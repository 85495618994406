import React, { useEffect, useState, useCallback } from 'react';
import { apiService } from '../../services/apiService';
import Loader from '../../templates/Loader';
import NewsFeedNewsCard from "../../templates/NewsFeed/NewsFeedNewsCard";
import { authenticationService } from '../../services/authenticationService';
import { Link } from 'react-router-dom';
import PlayerTeamLozenge from '../../templates/NewsFeed/PlayerTeamLozenge';
import NewsSummariesListing from "./NewsSummariesListing";

export default function NewsFeedListing({ searchParameters, onError }) {

    const [initiallyLoaded, setInitiallyLoaded] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [results, setResults] = useState([]);
    const [topPlayers, setTopPlayers] = useState([]);
    const [resultsTotal, setResultsTotal] = useState(0);
    const [page, setPage] = useState(1);


    const performSearch = useCallback(async () => {
            
        setPage(1);
        setLoadingData(true);

        apiService.apiGet(`${process.env.REACT_APP_API_URL}/mynews/keyword_search?search_keyword=${searchParameters.question}&page=1`)
            .then((resp) => {
                setResults(resp.data.keyword_search_result);
                setTopPlayers(resp.data.top_players);
                setInitiallyLoaded(true);
                setLoadingData(false);
                setResultsTotal(resp.data.count);
            },
                error => {
                    setResults([]);
                    setLoadingData(false);
                    setInitiallyLoaded(false);
                    if ('401' === error.message) {
                        authenticationService.logout();
                    } else {
                        onError(error.message + ' error');
                    }
                });
       

    }, [onError, searchParameters]);

    const updateSearch = async (newPage) => {       

        setLoadingData(true);
        setPage(newPage);

        apiService.apiGet(`${process.env.REACT_APP_API_URL}/mynews/keyword_search?search_keyword=${searchParameters.question}&page=${newPage}`)
            .then((resp) => {
                setResults(old => [...old, ...resp.data.keyword_search_result]);
                setInitiallyLoaded(true);
                setLoadingData(false);
            },
            error => {
                setResults([]);
                setLoadingData(false);
                setInitiallyLoaded(false);
                if ('401' === error.message) {
                    authenticationService.logout();
                } else {
                    onError(error.message + ' error');
                }
            });
        

    };

    const loadMoreHandler = () => {       
        updateSearch(page+1);
    };

    useEffect(() => {
        performSearch();
    }, [performSearch]);

    return (
        <div>
            <div className='mb-4'>
                {!initiallyLoaded ? (
                    <Loader />
                ) : (               
                    
                    <div className='bg-dark-mode-700 p-5 rounded-3'>                        
                        {topPlayers && <>
                            <p className="small fw-semi-bold">Top {topPlayers.length} mention{1 === topPlayers.length ? '' : 's'}</p>
                            <div className='d-grid grid-2-cols grid-gap-3 mb-25' style={{'gridAutoFlow': 'column','gridTemplateRows': `repeat(${Math.ceil(topPlayers.length/2)}, 1fr)`}}>
                                {topPlayers.map(function (data, i) {
                                    //let data = { 'name': `${object.first_name} ${object.last_name}`, 'uuid': object.uuid, 'image': object.image, 'following': object.following  };
                                    return <div className='d-flex' key={i}><Link to={`/playerdetail/${data.uuid}`} className='d-flex bg-dark-mode-500  rounded-4 align-self-start text-decoration-none justify-content-between align-items-center' ><span className="ps-25 pe-3">{i+1}</span><PlayerTeamLozenge data={data}  /></Link></div>
                                })}
                            </div>
                        </>}
                        <Link to="/newsfeed/managewatchlist" className="small" title="Manage your watchlist">Manage your watchlist</Link>                    
                    </div>
                
                )}
             </div>
                <NewsSummariesListing searchParameters={searchParameters} onError={onError}/> 
              {!initiallyLoaded ? (
                <Loader />
            ) : (
                <>
                <p className="large fw-semi-bold mb-n2 pt-4">Your news feed</p>
                    {(!results || 0 === results.length) ?
                        <p className='mt-3 small'>No results</p> :
                        <>    {results && results.map(function (object, i) {
                            return <div key={i} className={`py-55 ${(i + 1) !== results.length ? 'border-bottom border-dark-mode-50' : ''}`}>
                                <NewsFeedNewsCard data={object} />
                            </div>
                        })
                        }
                            {loadingData && <Loader />}
                            {results.length < resultsTotal && (
                                <button
                                    onClick={loadMoreHandler}
                                    className="btn btn-md bg-fluoro-green-500  w-100  small flex-0 fw-semi-bold mt-3  position-relative"
                                    disabled={loadingData}
                                >
                                    Load more {loadingData && <span className="spinner-border spinner-border-sm position-absolute top-50 start-50 text-white mx-n2 mt-n2" role="status" aria-hidden="true"></span>}
                                </button>
                            )}
                        </>
                    }
                </>
            )}

        </div>
    );
}
import PlayerIcon from "../../images/player_icon.svg";
import { Link } from 'react-router-dom';
import React, {useState} from 'react';
export default function NewsFeedNewsSummaryCard({ props, data = {} }) {

    const { following_name, following_image, following_uuid, text, sources } = { ...data };

    const sourceShowNOnLoad= 5;
    const imageLoadError = (e) => {
        e.target.src = PlayerIcon;
    };

    
    const [showMoreSources, setShowMoreSources] = useState(false);

    return (
        <div className="rounded-2 bg-dark-mode-300  h-100 w-100   p-3 " target='_blank' rel='noreferrer' href={following_name}>
            <Link to={`/playerdetail/` + following_uuid} className={`d-flex bg-dark-mode-300 text-decoration-none d-flex flex-row align-items-center justify-content-start rounded-4 w-auto mb-3`}>
                <div className="position-relative me-2">
                    {following_image ?
                        <img className={`object-fit-cover disc-5 disc-md-55 rounded-circle`} onError={imageLoadError} src={following_image} alt={following_name} />
                        :
                        <img className={`object-fit-cover disc-5 disc-md-55 rounded-circle`} src={PlayerIcon} alt={following_name} />

                    }
                    <div className='bg-fio-success-500 rounded-circle disc-3 p-1 center-content position-absolute bottom-0 end-0'> <i className='fa-light fa-check  small'></i></div>
                </div>

                <p className="mb-0 me-3 small fw-normal">
                    {following_name}
                </p>
            </Link>
            <p className="small mb-0 ">{text}</p>
            {sources && 0 < sources.length &&
                <div className="d-flex align-items-center justify-content-end mt-3">
                    <div className="d-flex align-items-between align-items-center" >
                        <span className='text-neutral-gray-700 extra-small pe-3 text-nowrap'>{sources.length} source{sources.length === 1 ? '' : 's'}</span>
                        <div>
                            {sources.slice(0,sourceShowNOnLoad).map(function (object, i) {
                                return <a target='_blank' rel='noreferrer' href={object.source_url} title={object.source_url}><img className={`object-fit-cover disc-45 disc-md-5 rounded-circle border border-2 border-neutral-gray-700 ms-n2`} src={'https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=' + object.source_url + '&size=64'} alt={''} /></a>
                            })
                            }
                            {sources.length > sourceShowNOnLoad &&
                                <span className='text-neutral-gray-700 extra-small text-nowrap ps-1 cursor-pointer' onClick={() => setShowMoreSources(!showMoreSources)}>{`+ ${sources.length - sourceShowNOnLoad} more`}</span>
                            }
                        </div>
                    </div>
                </div>

            }
          
        {sources.length > sourceShowNOnLoad && showMoreSources && 
            <div className="d-flex justify-content-end flex-wrap ">
                {sources.slice(sourceShowNOnLoad,sources.length).map(function (object, i) {
                    return <a target='_blank' rel='noreferrer' href={object.source_url} title={object.source_url}><img className={`mt-1 object-fit-cover disc-45 disc-md-5 rounded-circle border border-2 border-neutral-gray-700 ms-n2`} src={'https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=' + object.source_url + '&size=64'} alt={''} /></a>
                })
                }
            </div>
        }
        </div>
    );
}

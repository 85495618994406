import FilterItem from "./FilterItem";

const FilterSection = ({
  title,
  options,
  selected,
  onChange,
  type,
  ageFrom,
  ageTo,
  onAgeChange,
  counts,
  onShowAllClick
}) => {

  const indentedOptions = ['Centre forward', 'Left wing', 'Right wing','Defensive midfielder','Centre back'];
  return (
    <div className="mb-4">
      <h6 className='text-neutral-gray-700 fw-semi-bold mb-2'>{title}</h6>
      {type === 'range' ? (
        <div className="d-flex">
          <div className="me-3">
            <label htmlFor="from" className="form-label mb-1 fw-normal">From</label>
            <input
              className="form-control form-control-md text-black border-0 fw-semi-bold"
              type="number"
              value={ageFrom}
              onChange={(e) => onAgeChange('from', e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="to" className="form-label mb-1 fw-normal">To</label>
            <input
              className="form-control form-control-md text-black border-0 fw-semi-bold"
              type="number"
              value={ageTo}
              onChange={(e) => onAgeChange('to', e.target.value)}
            />
          </div>
        </div>
      ) : (
        options.map((option, index) => (
          <FilterItem
            key={option}
            label={option}
            isChecked={selected.includes(option)}
            onChange={() => onChange(option)}
            count={counts[index]}
            className={indentedOptions.includes(option) ? 'ms-5' : ''}
          />
        ))
      )}
      {type !== 'range' && (
        <div className='text-decoration-underline cursor-pointer mt-1 mb-2' onClick={onShowAllClick}>
          Show all
        </div>
      )}
    </div>
  );
};

export default FilterSection;
  
import React, { useState } from 'react';
import HelperFunctions from '../helpers/HelperFunctions';
import { PlayerSVG } from './SvgIcons';

const PlayerCardWatchlist = ({ data, onSelect }) => {
    const { firstNameExtracted } = HelperFunctions();
    const { first_name, last_name, following, image } = data;
    const [isFollowed, setIsFollowed] = useState(following === 1);

    const handleFollowClick = () => {
        if (!isFollowed) {
            setIsFollowed(true);
            onSelect(data);
        }
    };

    return (
        <>
            <div className="px-2 py-3 d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    {image ? (
                        <img
                            className="object-fit-cover disc-65 rounded-circle bg-white mb-0 me-3"
                            src={image}
                            alt={first_name}
                        />
                    ) : (
                        <PlayerSVG className="disc-65 mb-0 me-3" />
                    )}
                    <div className="d-flex flex-column align-items-start me-1">
                        <p className="d-block mb-0 fw-normal text-center small text-decoration-none text-white">
                            {firstNameExtracted(first_name) || 'Luke'}{' '}
                            <strong className="fw-semi-bold">{last_name || 'Shaw'}</strong>
                        </p>
                    </div>
                </div>
                {!isFollowed && (
                    <div
                        className="btn text-crayola-red-300 border-1 small fw-normal py-1 px-2half text-center border border-crayola-red-300 rounded-pill badge rounded-pill"
                        onClick={handleFollowClick}
                    >
                        Follow
                    </div>
                )}
                {isFollowed && (
                    <div className="text-neutral-gray-800 border-1 small fw-normal py-1 px-2half text-center bg-zero-dark-300 rounded-pill badge rounded-pill">
                        Following
                    </div>
                )}
            </div>
        </>
    );
};

export default PlayerCardWatchlist;


import HelperFunctions from '../../helpers/HelperFunctions';
import PlayerTeamLozenge from './PlayerTeamLozenge';
import NewsImage from './NewsImage';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../../services/apiService';
import { useEffect, useState } from 'react';

export default function NewsFeedNewsCard({ props, data = {} }) {

    const [isBookmarked, setIsBookmarked] = useState(false);
    const { stringTruncate, durationFormatter } = HelperFunctions();
    const { text, source_name, source_url, news_date, media, recognised_players, recognised_team, saved } = { ...data };
    const navigate = useNavigate();

    const handleCardClick = (player) => {
        navigate(`/playerdetail/${player.uuid}`);
    };

    useEffect(() => {
        setIsBookmarked(saved);
    }, [saved]);

    const handleBookmarkClick = () => {
        if (!isBookmarked) {
            // Save the news
            apiService.apiPostJson(`${process.env.REACT_APP_API_URL}/mynews/news/save`, { news_id: data.id })
                .then(() => {
                    setIsBookmarked(true);
                })
                .catch(error => {
                    console.log('Failed to save news. ' + error.message);
                });
        } else {
            // Remove the saved news
            apiService.apiPostJson(`${process.env.REACT_APP_API_URL}/mynews/news/remove`, { news_id: data.id })
                .then(() => {
                    setIsBookmarked(false);
                })
                .catch(error => {
                    console.log('Failed to remove news. ' + error.message);
                });
        }
    };

    return (
        <div className="d-flex flex-row h-100 w-100 justify-content-between text-decoration-none" >
            <div className="d-flex flex-column h-100  w-100 justify-content-between"  >
                <div>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <div className="d-flex align-items-center justify-content-start me-2">
                            <img className="object-fit-contain disc-3 disc-md-4 rounded-half flex-none bg-white object-fit-cover" src={'https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=' + source_url + '&size=64'} alt="icon" />
                            <p className="mb-0 ms-2 small">{source_name && <span>{source_name}&nbsp;&middot;&nbsp;</span>}{news_date && <span className='text-neutral-gray-700'>{durationFormatter(new Date(news_date).valueOf())}</span>}</p>
                        </div>
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="d-flex align-items-center justify-content-end">
                                {isBookmarked ? (
                                    <p className='text-crayola-red-500 mb-0'> Saved for later <i
                                        title="Remove Bookmark"
                                        className="fa-solid fa-bookmark cursor-pointer ps-2"
                                        onClick={handleBookmarkClick}
                                    ></i></p>
                                ) : (
                                    <p className='mb-0'> Save for later <i
                                        title="Bookmark"
                                        className="fa-light fa-bookmark cursor-pointer ps-2"
                                        onClick={handleBookmarkClick}
                                    ></i></p>
                                )}
                            </div>

                        </div>
                    </div>
                   
                        <a target='_blank' rel='noreferrer' href={source_url} className='text-decoration-none'>
                            {media && !media.includes('twitter-blue', 0) &&
                                <div className='w-100 mb-3' >
                                    <NewsImage src={media} alt="News image" />
                                </div>
                            }
                            <p className={'text-neutral-gray-500' + (recognised_players || recognised_team) ? 'mb-1' : 'mb-3'} >{stringTruncate(text, 450)}</p>
                        </a>
                   
                </div>
                <div className="d-flex justify-content-end align-items-start flex-wrap flex-1">
                    {recognised_players && recognised_players.map((data, i) => <div onClick={() => handleCardClick(data)} className='ms-2 mt-2 cursor-pointer' key={i} ><PlayerTeamLozenge data={data} /></div>)}
                    {recognised_team && recognised_team.map((data, i) => <div onClick={() => handleCardClick(data)} className='ms-2 mt-2 cursor-pointer' key={i} ><PlayerTeamLozenge data={data} /></div>)}
                </div>
            </div>
        </div>
    );
}

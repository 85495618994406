import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { authenticationService } from '../services/authenticationService';
import Loader from '../templates/Loader';
import Home from '../pages/Home';
import LoggedOutHome from '../pages/LoggedOutHome';

const PrivateRoute = ({ element: Component }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [initialised, setInitialised] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const subscription = authenticationService.currentUser.subscribe(data => {
      setCurrentUser(data);
      setInitialised(true);
    });
    
    return () => subscription.unsubscribe();
  }, []);

  if (!initialised) {
    return <Loader />; // loader/spinner
  }

  if (currentUser) {
    return location.pathname === '/' ? <Home /> : <Component />;
  } else {
    return location.pathname === '/' ? <LoggedOutHome /> : <Navigate to="/login" state={{ from: location }} />;
  }
};

export default PrivateRoute;

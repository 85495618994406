import { NavLink, Route, Routes, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import './scss/app.scss';
import './scss/fontawesome6.5.2/scss/fontawesome.scss';
import './scss/fontawesome6.5.2/scss/solid.scss';
import './scss/fontawesome6.5.2/scss/light.scss';
import './scss/fontawesome6.5.2/scss/regular.scss'

import Home from './pages/Home';
import PlayerDetail from './pages/PlayerDetail';
import PlayerNews from './pages/PlayerNews';
import NewsFeed from './pages/NewsFeed/NewsFeed';
import ManagePlayersWatchlist from './pages/NewsFeed/ManagePlayersWatchlist';
import Login from './pages/Login';
import PrivateRoute from './hooks/PrivateRoute';
import PlayerListing from './pages/PlayerListing';
import PerfectPlayer from './pages/PerfectPlayer';
import ScoutAssistBasic from './pages/ScoutAssistBasic';
import ScoutAssistChat from './pages/ScoutAssist';
import Header from './components/Header';
import { slide as Menu } from 'react-burger-menu'
import { authenticationService } from './services/authenticationService';
import ManageSavedNews from './pages/NewsFeed/ManageSavedNews';

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();

  const CustomBurgerIcon = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="white"><path d="M0 88C0 74.7 10.7 64 24 64H424c13.3 0 24 10.7 24 24s-10.7 24-24 24H24C10.7 112 0 101.3 0 88zM0 248c0-13.3 10.7-24 24-24H424c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24zM448 408c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24s10.7-24 24-24H424c13.3 0 24 10.7 24 24z" /></svg>
  const CustomCrossIcon = () => <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512' fill='white'><path d='M324.5 411.1c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L214.6 256l132.5-132.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L192 233.4 59.5 100.9c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L169.4 256 36.9 388.5c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L192 278.6l132.5 132.5z' /></svg>

  var styles = {
    bmBurgerButton: {
      position: 'absolute',
      width: '1rem',
      height: '1rem',
      left: '1.25rem',
      top: '1.75rem'
    },
    bmBurgerBars: {
      background: 'white'
    },
    bmBurgerBarsHover: {
      background: '#a90000'
    },
    bmCrossButton: {
      height: '1rem',
      width: '1rem',
      right: '1rem',
      top: '1rem'
    },
    bmCross: {
      background: 'white'
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%'
    },
    bmMenu: {
      background: 'rgba(48, 51, 58, 1)',
      padding: '0',
      fontSize: '1rem'
    },
    bmMorphShape: {
      fill: '#373a47'
    },

    bmItem: {
      display: 'inline-block'
    },
    bmOverlay: {
      background: 'rgba(58, 60, 67, .75)'
    }
  }

  const handleMenuOpen = () => {
    setMenuOpen(!menuOpen)
  }
  const closeMenu = () => {
    setMenuOpen(false)
  }

  useEffect(() => {
    const subscription = authenticationService.currentUser.subscribe(user => {
      setCurrentUser(user);
    });

    return () => subscription.unsubscribe();
  }, []);
  const handleLogout = () => {
    authenticationService.logout();
    closeMenu()
    navigate('/login');
  };
  return (
    <>
      <div className='d-block d-md-none'>
        <Menu
          isOpen={menuOpen}
          onOpen={handleMenuOpen}
          onClose={handleMenuOpen}
          customBurgerIcon={<CustomBurgerIcon />}
          customCrossIcon={<CustomCrossIcon />}
          styles={styles}
        >
          {currentUser && (
            <ul className="navbar-nav mb-0 flex-row ms-3 mt-3">
              <li className="nav-item">
                <NavLink
                  onClick={closeMenu}
                  className="nav-link fw-normal small text-decoration-none border-body-text"
                  activeclassname="active"
                  to="/"
                >
                  Insight dashboard
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  onClick={closeMenu}
                  className="nav-link fw-normal small text-decoration-none border-body-text"
                  activeclassname="active"
                  to="/newsfeed"
                >
                  News and insights
                </NavLink>
                <ul className="list-unstyled ms-5">
                  <li className="nav-item">
                    <NavLink
                      onClick={closeMenu}
                      className="nav-link fw-normal small text-decoration-none border-body-text"
                      activeclassname="active"
                      to="/newsfeed/managewatchlist"
                    >
                      Your watchlist
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      onClick={closeMenu}
                      className="nav-link fw-normal small text-decoration-none border-body-text"
                      activeclassname="active"
                      to="/savednews"
                    >
                      Saved for later
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <div
                  onClick={handleLogout}
                  className="nav-link fw-normal small text-decoration-none border-body-text"
                >
                  Logout
                </div>
              </li>
            </ul>
          )}

          {!currentUser && (
            <ul className="navbar-nav mb-0 flex-row ms-3 mt-3">
              <li className="nav-item">
                <NavLink
                  onClick={closeMenu}
                  className="nav-link fw-normal small text-decoration-none border-body-text"
                  activeclassname="active"
                  to="/login"
                >
                  Login
                </NavLink>
              </li>
            </ul>
          )}
        </Menu>

      </div>
      <div className="container">
        <Header />
        <main className='py-4'>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<PrivateRoute element={Home} />} />
            <Route path="/playernews" element={<PrivateRoute element={PlayerNews} />} />
            <Route path="/newsfeed" element={<PrivateRoute element={NewsFeed} />} />
            <Route path="/newsfeed/managewatchlist" element={<PrivateRoute element={ManagePlayersWatchlist} />} />
            <Route path="/savednews" element={<PrivateRoute element={ManageSavedNews} />} />
            <Route path="/playerdetail/:uuid" element={<PrivateRoute element={PlayerDetail} />} />
            <Route path="/playerlisting" element={<PrivateRoute element={PlayerListing} />} />
            <Route path="/perfectplayer" element={<PrivateRoute element={PerfectPlayer} />} />
            <Route path="/scoutassist_basic/:example" element={<PrivateRoute element={ScoutAssistBasic} />} />
            <Route path="/scoutassist/" element={<PrivateRoute element={ScoutAssistChat} />} />
          </Routes>
        </main>
        <footer>
        </footer>
      </div>
    </>
  );
}

export default App;

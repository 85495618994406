import React, { useState } from 'react';
import NewsCard from "../templates/NewsCard";
import { WonderkidsPlayerInfo } from '../services/content';

export default function InformationSources({ props }) {
    const [showAll, setShowAll] = useState(false);

    const handleShowMore = () => {
        setShowAll(!showAll);
    };


    return (
        <div className="mb-3">
            <div className={`position-relative ${showAll ? ' pb-6' : ''}`}>
                <p className="mb-0">Information sources</p>
                <p className="mb- text-neutral-gray-700 fw-normal small">6 sources captured</p>
                <div className={`d-grid grid-md-4-cols grid-gap-3 ${showAll ? 'show-all' : 'show-limited'}`}>
                    {WonderkidsPlayerInfo.map((object, i) => (
                        <div key={i} className={`d-flex flex-none flex-md-auto me-1half me-md-0 w-100 bg-dark-mode-300 rounded-2 position-relative text-decoration-none p-3 ${i === 2 && !showAll ? 'half-height' : ''}`}>
                            <NewsCard data={object} />
                        </div>
                    ))}
                </div>
                <div className='d-flex d-md-none'>
                    <div className={`center-content position-absolute  start-50 translate-middle-x ${showAll ? 'bottom-0' : 'bottom-5'}`} onClick={handleShowMore}>
                        <i className={`fa-regular  ${showAll ? 'fa-arrow-up' : 'fa-arrow-down'} me-2`}></i> <span>{showAll ? 'Show less' : 'Show more'}</span> <i className={`fa-regular  ${showAll ? 'fa-arrow-up' : 'fa-arrow-down'} ms-2`}></i>
                    </div>
                </div>
            </div>
        </div>
    );
}

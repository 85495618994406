import React from 'react'
import { PlayerSVG, TeamSVG } from './SvgIcons';

const UnfollowCard = ({ data = {}, handleCloseFollowModal, handleUnfollow }) => {
    const { first_name, last_name, image, } = data;
    const fullName = `${first_name || ''} ${last_name || ''}`.trim();
    const team_image = data.team?.image || data.team_image;
    const team_name = data.team?.name || data.team || data.team_name;
    const first_name_extracted = first_name?.split(' ')[0];

    return (
        <>
            <div className='p-0'>
                <div className='center-content flex-column w-100 mb-0'>
                    {image ?
                        <img className={`object-fit-cover disc-9 rounded-circle bg-white mb-3`} src={image} alt={fullName} />
                        :
                        <PlayerSVG className='disc-8 mb-3' />
                    }
                    <p className="mb-0 large fw-normal text-center mb-2">
                        {first_name_extracted} <strong className="fw-semi-bold">{last_name}</strong>
                    </p>
                    <div className='center-content mb-4'>
                        {team_image ?
                            <img className="object-fit-cover disc-35 rounded-circle me-2" src={team_image} alt={team_name || "Manchester United"} />
                            :
                            <TeamSVG />
                        }
                        <p className="mb-0 small">{team_name || "Manchester United, England"}</p>
                    </div>
                    <div className='w-100 px-2'>
                        <div className='btn btn-lg text-white bg-fio-error-500 rounded-2 w-100 mb-2' onClick={() => handleUnfollow(data)}>
                            UnFollow
                        </div>
                    </div>
                    <div className='text-decoration-underline text-center mb-1 cursor-pointer' onClick={handleCloseFollowModal}>Cancel</div>
                </div>
            </div>

        </>
    )
}

export default UnfollowCard
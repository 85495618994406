import Daniel_Udoh from '../images/Daniel-Udoh.png';
import Luke_Shaw from '../images/playerImage-sm.png';
import Benjamin from '../images/Benjamin.png';
import jack from '../images/jack.png';
import Alan_Varela from '../images/14241914.png';
import Nestory from '../images/nestory.webp';
import Lamine from '../images/Lamine Yamal.webp';
import endrick from '../images/endrick-palmeiras.webp';
import Jonathon_Leko from '../images/1583d7798da208c3855aa055d201d98b.png';
import AdelaideImg from '../images/adelaide.png';
import shrewsburyImg from '../images/3054.png';
import Danny from '../images/danny.webp';
import salah from '../images/salah.webp';
import Jude from '../images/jude.webp';
import fcpImg from '../images/fcp.png';
import interMiamiImg from '../images/interMiami.png';
import brightonImg from '../images/brighton.png';
import brewersImg from '../images/brewers.png';
import LiverpoolImg from '../images/livepool.png';
import RealMadridImg from '../images/realMadrid.png';
import ManchesterImg from '../images/team-flag.png'
import premierflag from '../images/premier-league.png'
import FAflag from '../images/emirates-FA.svg'
import tacaLImg from '../images/Taça_PortugalLeageue.gif'
import leagueOneImg from '../images/league-one-fixtures.jpg'
import EFLLeague from '../images/EFLLeague.png'
import CopaLeague from '../images/Copa-Del-Rey-Tour-Logo.png'
import LigaLeague from '../images/liga.png'
import UEFA from '../images/UEFA_Champions_League_Logo.webp'
import carabaoCupImg from '../images/carabao-cup-league-cup-logo-efl-trophy_1knj8k4gphisq1fwe5crj4i8bo.jpg'
import mlsImg from '../images/MLS.png'
import usclub from '../images/usclub.png'
import bbc from '../images/bbc.png'
import uol from '../images/uol.png'
import espn from '../images/espn.png'

export const summaries = [{
    "following_name": "Luke Shaw",
    "following_image": "https://media.api-sports.io/football/players/891.png",
    "following_uuid": "965aee3f-fe71-4862-841e-c440c5e7a82d",
    "text": "some text",
    "sources":
        [
            {
                "source_name": "Mirror",
                "source_url": "https://www.mirror.co.uk/sport/football/news/ten-hag-sacking-maguire-rashford-33999839",

            },
            {
                "source_name": "ESPN",
                "source_url": "https://www.espn.co.uk/football/player/_/id/157073/harry-maguire",

            }
            ,
            {
                "source_name": "Manchester Evening News",
                "source_url": "https://www.manchestereveningnews.co.uk/sport/football/football-news/harry-maguire-shows-true-colours-30237556",

            }
        ]
},

{
    "following_name": "Harry Maguire",
    "following_image": "https://media.api-sports.io/football/players/2935.png",
    "following_uuid": "1f920949-cca6-427b-8327-c0bcb965927a",
    "text": "Maguire had been stripped of the captain's armband by Ten Hag in the summer of 2023 and quickly fell down the pecking order. Rashford meanwhile had a number of run-ins with the former United boss. Most notably, he was dropped following an unsanctioned night out in Belfast, which also led to a club fine. According to the Daily Star, Rashford was among three stars in particular who let down Ten Hag and were partially responsible for his ousting.",
    "sources":
        [
            {
                "source_name": "Mirror",
                "source_url": "https://www.mirror.co.uk/sport/football/news/ten-hag-sacking-maguire-rashford-33999839",

            }
        ]
}
    ,

{
    "following_name": "Axel Tuanzebe",
    "following_image": "https://media.api-sports.io/football/players/19182.png",
    "following_uuid": "92a0731e-368c-41ac-9e7e-f956a357ed09",
    "text": "Axel Tuanzebe, former Manchester United and Aston Villa defender, is out for at least a month after a freak hand injury while washing dishes.",
    "sources":
    [
        {
            "source_name": "Mirror",
            "source_url": "https://www.mirror.co.uk/sport/football/news/ten-hag-sacking-maguire-rashford-33999839",

        },
        {
            "source_name": "ESPN",
            "source_url": "https://www.espn.co.uk/football/player/_/id/157073/harry-maguire",

        }
        ,
        {
            "source_name": "Manchester Evening News",
            "source_url": "https://www.manchestereveningnews.co.uk/sport/football/football-news/harry-maguire-shows-true-colours-30237556",

        }
    ]
}

];

export const playerInfo = [
    {
        name: "Daniel Udoh",
        age: "24",
        role: "Centre Forward",
        imageUrl: Daniel_Udoh,
        clubImg: shrewsburyImg,
        team: "Shrewsbury Town",
        clubName: "Shrewsbury Town, England",
        contractExpires: "11th March 2028",
        lastContract: "1st July 2023",
        citizenships: "England",
        injuryUpdate: "Daniel Udoh has been sidelined with a ligament injury, expected to be out for 3 months.",
        newsLogo: bbc,
        newsName: "BBC News",
        playerTransferHistory: [
            {
                club: "Shrewsbury Town",
                clubImg: shrewsburyImg,
                season: "19/20",
                transferType: "Undisclosed"
            },
            {
                club: "Telford United",
                clubImg: usclub,
                season: "18/19",
                transferType: "Free transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: fcpImg,
                season: "17/18",
                transferType: "End of loan"
            },
            {
                club: "Leamington",
                clubImg: LiverpoolImg,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: shrewsburyImg,
                season: "17/18",
                transferType: "End of loan"
            },
            {
                club: "Chester",
                clubImg: usclub,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: fcpImg,
                season: "17/18",
                transferType: "End of loan"
            },
            {
                club: "Halesowen Town",
                clubImg: brewersImg,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: shrewsburyImg,
                season: "16/17",
                transferType: "End of loan"
            },
            {
                club: "Solihull Moors",
                clubImg: brewersImg,
                season: "16/17",
                transferType: "Loan transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: LiverpoolImg,
                season: "15/16",
                transferType: "Undisclosed"
            },
            {
                club: "Ilkeston",
                clubImg: fcpImg,
                season: "15/16",
                transferType: "Free transfer"
            },
            {
                club: "Grays Athletic FC",
                clubImg: brewersImg,
                season: "14/15",
                transferType: "Free transfer"
            },
            {
                club: "Wingate & Finchley FC",
                clubImg: LiverpoolImg,
                season: "14/15",
                transferType: "Free transfer"
            }
        ],
        leagueStatistics: [
            {
                leagueName: "Premier League 23/24",
                leagueLogo: premierflag,
                country: "England",
                performance: {
                    appearances: 22,
                    goals: 7,
                    assists: 4
                },
                discipline: {
                    yellowCards: 3,
                    secondYellows: 1,
                    redCards: 1
                }
            },
            {
                leagueName: "FA Cup 23/24",
                leagueLogo: FAflag,
                country: "England",
                performance: {
                    appearances: 5,
                    goals: 2,
                    assists: 1
                },
                discipline: {
                    yellowCards: 1,
                    secondYellows: 0,
                    redCards: 0
                }
            },
        ]
    },
    {
        name: "Danny Namaso",
        age: "23",
        role: "Centre-Forward",
        imageUrl: Danny,
        clubImg: fcpImg,
        team: "FC Porto",
        clubName: "FC Porto, Portugal",
        contractExpires: "30th June 2027",
        lastContract: "15th May 2023",
        citizenships: "England",
        injuryUpdate: "Danny Namaso suffered a hamstring tear during training and will undergo further assessment.",
        newsLogo: uol,
        newsName: "UOL",
        playerTransferHistory: [
            {
                club: "Grays Athletic FC",
                clubImg: brewersImg,
                season: "14/15",
                transferType: "Free transfer"
            },
            {
                club: "Telford United",
                clubImg: usclub,
                season: "18/19",
                transferType: "Free transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: shrewsburyImg,
                season: "17/18",
                transferType: "End of loan"
            },
            {
                club: "Solihull Moors",
                clubImg: brewersImg,
                season: "16/17",
                transferType: "Loan transfer"
            },
            {
                club: "Leamington",
                clubImg: LiverpoolImg,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: fcpImg,
                season: "15/16",
                transferType: "Undisclosed"
            },
            {
                club: "Ilkeston",
                clubImg: fcpImg,
                season: "15/16",
                transferType: "Free transfer"
            },
            {
                club: "Halesowen Town",
                clubImg: brewersImg,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: shrewsburyImg,
                season: "16/17",
                transferType: "End of loan"
            },
            {
                club: "Chester",
                clubImg: usclub,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: fcpImg,
                season: "17/18",
                transferType: "End of loan"
            },
            {
                club: "Wingate & Finchley FC",
                clubImg: LiverpoolImg,
                season: "14/15",
                transferType: "Free transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: LiverpoolImg,
                season: "15/16",
                transferType: "Undisclosed"
            },
            {
                club: "Shrewsbury Town",
                clubImg: shrewsburyImg,
                season: "19/20",
                transferType: "Undisclosed"
            }
        ],
        leagueStatistics: [
            {
                leagueName: "Taça de Portugal 23/24",
                leagueLogo: tacaLImg,
                country: "Portugal",
                performance: {
                    appearances: 8,
                    goals: 3,
                    assists: 2
                },
                discipline: {
                    yellowCards: 1,
                    secondYellows: 0,
                    redCards: 0
                }
            },
            {
                leagueName: "Primeira Liga 23/24",
                leagueLogo: premierflag,
                country: "Portugal",
                performance: {
                    appearances: 25,
                    goals: 10,
                    assists: 5
                },
                discipline: {
                    yellowCards: 2,
                    secondYellows: 0,
                    redCards: 0
                }
            },
            {
                leagueName: "League One 23/24",
                leagueLogo: leagueOneImg,
                country: "England",
                performance: {
                    appearances: 30,
                    goals: 12,
                    assists: 7
                },
                discipline: {
                    yellowCards: 4,
                    secondYellows: 1,
                    redCards: 0
                }
            },
        ]
    },
    {
        name: "Jonathon Leko",
        age: "23",
        role: "Centre Forward",
        imageUrl: Jonathon_Leko,
        clubImg: brewersImg,
        team: "Burton Albion FC",
        clubName: "Burton Albion FC, England",
        contractExpires: "15th August 2026",
        lastContract: "2nd March 2023",
        citizenships: "England",
        injuryUpdate: "Jonathon Leko is recovering from a minor ankle sprain and is expected to return to training next week.",
        newsLogo: espn,
        newsName: "ESPN",
        playerTransferHistory: [
            {
                club: "Chester",
                clubImg: usclub,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Wingate & Finchley FC",
                clubImg: LiverpoolImg,
                season: "14/15",
                transferType: "Free transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: fcpImg,
                season: "17/18",
                transferType: "End of loan"
            },
            {
                club: "Telford United",
                clubImg: usclub,
                season: "18/19",
                transferType: "Free transfer"
            },
            {
                club: "Shrewsbury Town",
                clubImg: shrewsburyImg,
                season: "19/20",
                transferType: "Undisclosed"
            },
            {
                club: "Leamington",
                clubImg: LiverpoolImg,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Grays Athletic FC",
                clubImg: brewersImg,
                season: "14/15",
                transferType: "Free transfer"
            },
            {
                club: "Halesowen Town",
                clubImg: brewersImg,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Ilkeston",
                clubImg: fcpImg,
                season: "15/16",
                transferType: "Free transfer"
            },
            {
                club: "Solihull Moors",
                clubImg: brewersImg,
                season: "16/17",
                transferType: "Loan transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: shrewsburyImg,
                season: "16/17",
                transferType: "End of loan"
            },
            {
                club: "Crewe Alexandra",
                clubImg: LiverpoolImg,
                season: "15/16",
                transferType: "Undisclosed"
            },
            {
                club: "Crewe Alexandra",
                clubImg: shrewsburyImg,
                season: "17/18",
                transferType: "End of loan"
            },
            {
                club: "Crewe Alexandra",
                clubImg: fcpImg,
                season: "17/18",
                transferType: "End of loan"
            }
        ],
        leagueStatistics: [
            {
                leagueName: "League One 23/24",
                leagueLogo: leagueOneImg,
                country: "England",
                performance: {
                    appearances: 30,
                    goals: 12,
                    assists: 7
                },
                discipline: {
                    yellowCards: 4,
                    secondYellows: 1,
                    redCards: 0
                }
            },
            {
                leagueName: "EFL Trophy 23/24",
                leagueLogo: EFLLeague,
                country: "England",
                performance: {
                    appearances: 6,
                    goals: 3,
                    assists: 1
                },
                discipline: {
                    yellowCards: 1,
                    secondYellows: 0,
                    redCards: 0
                }
            },
            {
                leagueName: "Copa del Rey 23/24",
                leagueLogo: CopaLeague,
                country: "Spain",
                performance: {
                    appearances: 5,
                    goals: 2,
                    assists: 1
                },
                discipline: {
                    yellowCards: 1,
                    secondYellows: 0,
                    redCards: 0
                }
            },
        ]
    },
    {
        name: "Mohamed Salah",
        age: "32",
        role: "Right Winger",
        imageUrl: salah,
        clubImg: LiverpoolImg,
        team: "Liverpool FC",
        clubName: "Liverpool FC, Egypt",
        contractExpires: "20th December 2026",
        lastContract: "5th April 2022",
        citizenships: "Egypt",
        injuryUpdate: "Mohamed Salah picked up a calf strain and will be out for the next two fixtures.",
        newsLogo: bbc,
        newsName: "BBC News",
        leagueStatistics: [
            {
                leagueName: "Premier League 23/24",
                leagueLogo: premierflag,
                country: "England",
                performance: {
                    appearances: 28,
                    goals: 18,
                    assists: 10
                },
                discipline: {
                    yellowCards: 2,
                    secondYellows: 0,
                    redCards: 0
                }
            },
            {
                leagueName: "Copa del Rey 23/24",
                leagueLogo: CopaLeague,
                country: "Spain",
                performance: {
                    appearances: 5,
                    goals: 2,
                    assists: 1
                },
                discipline: {
                    yellowCards: 1,
                    secondYellows: 0,
                    redCards: 0
                }
            },
            {
                leagueName: "FA Cup 23/24",
                leagueLogo: FAflag,
                country: "England",
                performance: {
                    appearances: 6,
                    goals: 3,
                    assists: 1
                },
                discipline: {
                    yellowCards: 0,
                    secondYellows: 0,
                    redCards: 0
                }
            },
        ]
    },
    {
        name: "Jude Bellingham",
        age: "20",
        role: "Attacking Midfield",
        imageUrl: Jude,
        clubImg: RealMadridImg,
        team: "Real Madrid",
        clubName: "Real Madrid, Spain",
        contractExpires: "10th April 2028",
        lastContract: "1st June 2023",
        citizenships: "England",
        injuryUpdate: "Jude Bellingham has been diagnosed with a groin strain, sidelining him for the next month.",
        newsLogo: espn,
        newsName: "ESPN",
        playerTransferHistory: [
            {
                club: "Grays Athletic FC",
                clubImg: brewersImg,
                season: "14/15",
                transferType: "Free transfer"
            },
            {
                club: "Telford United",
                clubImg: usclub,
                season: "18/19",
                transferType: "Free transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: shrewsburyImg,
                season: "17/18",
                transferType: "End of loan"
            },
            {
                club: "Solihull Moors",
                clubImg: brewersImg,
                season: "16/17",
                transferType: "Loan transfer"
            },
            {
                club: "Leamington",
                clubImg: LiverpoolImg,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: fcpImg,
                season: "15/16",
                transferType: "Undisclosed"
            },
            {
                club: "Ilkeston",
                clubImg: fcpImg,
                season: "15/16",
                transferType: "Free transfer"
            },
            {
                club: "Halesowen Town",
                clubImg: brewersImg,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: shrewsburyImg,
                season: "16/17",
                transferType: "End of loan"
            },
            {
                club: "Chester",
                clubImg: usclub,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: fcpImg,
                season: "17/18",
                transferType: "End of loan"
            },
            {
                club: "Wingate & Finchley FC",
                clubImg: LiverpoolImg,
                season: "14/15",
                transferType: "Free transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: LiverpoolImg,
                season: "15/16",
                transferType: "Undisclosed"
            },
            {
                club: "Shrewsbury Town",
                clubImg: shrewsburyImg,
                season: "19/20",
                transferType: "Undisclosed"
            }
        ],
        leagueStatistics: [
            {
                leagueName: "La Liga 23/24",
                leagueLogo: LigaLeague,
                country: "Spain",
                performance: {
                    appearances: 22,
                    goals: 8,
                    assists: 6
                },
                discipline: {
                    yellowCards: 5,
                    secondYellows: 1,
                    redCards: 0
                }
            },
            {
                leagueName: "Copa del Rey 23/24",
                leagueLogo: CopaLeague,
                country: "Spain",
                performance: {
                    appearances: 5,
                    goals: 2,
                    assists: 1
                },
                discipline: {
                    yellowCards: 1,
                    secondYellows: 0,
                    redCards: 0
                }
            },
            {
                leagueName: "Premier League 23/24",
                leagueLogo: premierflag,
                country: "England",
                performance: {
                    appearances: 28,
                    goals: 18,
                    assists: 10
                },
                discipline: {
                    yellowCards: 2,
                    secondYellows: 0,
                    redCards: 0
                }
            },
        ]
    },
];



export const WonderkidsPlayerInfo = [
    {
        name: "Nestory Irankunda",
        age: "19",
        role: "Right Winger",
        imageUrl: Nestory,
        achievement: "First cap for national team at 18 years old",
        clubImg: AdelaideImg,
        clubName: "Adelaide Utd, Australia",
        contractExpires: "30th June 2027",
        lastContract: "4th April 2023",
        citizenships: "Australia",
        injuryUpdate: "Nestory Irankunda is recovering from a knee surgery, expected to be out for 2 months.",
        newsLogo: espn,
        newsName: "ESPN",
        playerTransferHistory: [
            {
                club: "Chester",
                clubImg: usclub,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Wingate & Finchley FC",
                clubImg: LiverpoolImg,
                season: "14/15",
                transferType: "Free transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: fcpImg,
                season: "17/18",
                transferType: "End of loan"
            },
            {
                club: "Telford United",
                clubImg: usclub,
                season: "18/19",
                transferType: "Free transfer"
            },
            {
                club: "Shrewsbury Town",
                clubImg: shrewsburyImg,
                season: "19/20",
                transferType: "Undisclosed"
            },
            {
                club: "Leamington",
                clubImg: LiverpoolImg,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Grays Athletic FC",
                clubImg: brewersImg,
                season: "14/15",
                transferType: "Free transfer"
            },
            {
                club: "Halesowen Town",
                clubImg: brewersImg,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Ilkeston",
                clubImg: fcpImg,
                season: "15/16",
                transferType: "Free transfer"
            },
            {
                club: "Solihull Moors",
                clubImg: brewersImg,
                season: "16/17",
                transferType: "Loan transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: shrewsburyImg,
                season: "16/17",
                transferType: "End of loan"
            },
            {
                club: "Crewe Alexandra",
                clubImg: LiverpoolImg,
                season: "15/16",
                transferType: "Undisclosed"
            },
            {
                club: "Crewe Alexandra",
                clubImg: shrewsburyImg,
                season: "17/18",
                transferType: "End of loan"
            },
            {
                club: "Crewe Alexandra",
                clubImg: fcpImg,
                season: "17/18",
                transferType: "End of loan"
            }
        ],
        leagueStatistics: [
            {
                leagueName: "A-League 23/24",
                leagueLogo: leagueOneImg,
                country: "Australia",
                performance: {
                    appearances: 18,
                    goals: 6,
                    assists: 3
                },
                discipline: {
                    yellowCards: 2,
                    secondYellows: 0,
                    redCards: 0
                }
            },
            {
                leagueName: "FA Cup 23/24",
                leagueLogo: FAflag,
                country: "England",
                performance: {
                    appearances: 8,
                    goals: 2,
                    assists: 1
                },
                discipline: {
                    yellowCards: 1,
                    secondYellows: 0,
                    redCards: 0
                }
            },
        ]
    },
    {
        name: "Benjamin Cremaschi",
        age: "18",
        role: "Central Midfielder",
        imageUrl: Benjamin,
        achievement: "High performance against top rivals",
        clubImg: interMiamiImg,
        clubName: "Inter Miami, USA",
        contractExpires: "30th June 2027",
        lastContract: "4th April 2023",
        citizenships: "USA",
        injuryUpdate: "Benjamin Cremaschi suffered a sprained ankle and will be out for 4 weeks.",
        newsLogo: bbc,
        newsName: "BBC News",
        playerTransferHistory: [
            {
                club: "Grays Athletic FC",
                clubImg: brewersImg,
                season: "14/15",
                transferType: "Free transfer"
            },
            {
                club: "Telford United",
                clubImg: usclub,
                season: "18/19",
                transferType: "Free transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: shrewsburyImg,
                season: "17/18",
                transferType: "End of loan"
            },
            {
                club: "Solihull Moors",
                clubImg: brewersImg,
                season: "16/17",
                transferType: "Loan transfer"
            },
            {
                club: "Leamington",
                clubImg: LiverpoolImg,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: fcpImg,
                season: "15/16",
                transferType: "Undisclosed"
            },
            {
                club: "Ilkeston",
                clubImg: fcpImg,
                season: "15/16",
                transferType: "Free transfer"
            },
            {
                club: "Halesowen Town",
                clubImg: brewersImg,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: shrewsburyImg,
                season: "16/17",
                transferType: "End of loan"
            },
            {
                club: "Chester",
                clubImg: usclub,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: fcpImg,
                season: "17/18",
                transferType: "End of loan"
            },
            {
                club: "Wingate & Finchley FC",
                clubImg: LiverpoolImg,
                season: "14/15",
                transferType: "Free transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: LiverpoolImg,
                season: "15/16",
                transferType: "Undisclosed"
            },
            {
                club: "Shrewsbury Town",
                clubImg: shrewsburyImg,
                season: "19/20",
                transferType: "Undisclosed"
            }
        ],
        leagueStatistics: [
            {
                leagueName: "MLS 23/24",
                leagueLogo: mlsImg,
                country: "USA",
                performance: {
                    appearances: 20,
                    goals: 4,
                    assists: 5
                },
                discipline: {
                    yellowCards: 3,
                    secondYellows: 0,
                    redCards: 0
                }
            },
            {
                leagueName: "US Open Cup 23/24",
                leagueLogo: usclub,
                country: "USA",
                performance: {
                    appearances: 7,
                    goals: 2,
                    assists: 2
                },
                discipline: {
                    yellowCards: 1,
                    secondYellows: 0,
                    redCards: 0
                }
            },
        ]
    },
    {
        name: "Jack Hinshelwood",
        age: "19",
        role: "Defensive Midfielder",
        imageUrl: jack,
        achievement: "Important goals scored for team",
        clubImg: brightonImg,
        clubName: "Brighton, England",
        contractExpires: "30th June 2027",
        lastContract: "4th April 2023",
        citizenships: "England",
        injuryUpdate: "Jack Hinshelwood is dealing with a back issue, expected to be sidelined for 3 weeks.",
        newsLogo: espn,
        newsName: "ESPN",
        playerTransferHistory: [
            {
                club: "Chester",
                clubImg: usclub,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Wingate & Finchley FC",
                clubImg: LiverpoolImg,
                season: "14/15",
                transferType: "Free transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: fcpImg,
                season: "17/18",
                transferType: "End of loan"
            },
            {
                club: "Telford United",
                clubImg: usclub,
                season: "18/19",
                transferType: "Free transfer"
            },
            {
                club: "Shrewsbury Town",
                clubImg: shrewsburyImg,
                season: "19/20",
                transferType: "Undisclosed"
            },
            {
                club: "Leamington",
                clubImg: LiverpoolImg,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Grays Athletic FC",
                clubImg: brewersImg,
                season: "14/15",
                transferType: "Free transfer"
            },
            {
                club: "Halesowen Town",
                clubImg: brewersImg,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Ilkeston",
                clubImg: fcpImg,
                season: "15/16",
                transferType: "Free transfer"
            },
            {
                club: "Solihull Moors",
                clubImg: brewersImg,
                season: "16/17",
                transferType: "Loan transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: shrewsburyImg,
                season: "16/17",
                transferType: "End of loan"
            },
            {
                club: "Crewe Alexandra",
                clubImg: LiverpoolImg,
                season: "15/16",
                transferType: "Undisclosed"
            },
            {
                club: "Crewe Alexandra",
                clubImg: shrewsburyImg,
                season: "17/18",
                transferType: "End of loan"
            },
            {
                club: "Crewe Alexandra",
                clubImg: fcpImg,
                season: "17/18",
                transferType: "End of loan"
            }
        ],
        leagueStatistics: [
            {
                leagueName: "Premier League 23/24",
                leagueLogo: premierflag,
                country: "England",
                performance: {
                    appearances: 15,
                    goals: 1,
                    assists: 2
                },
                discipline: {
                    yellowCards: 4,
                    secondYellows: 0,
                    redCards: 0
                }
            },
            {
                leagueName: "Carabao Cup 23/24",
                leagueLogo: carabaoCupImg,
                country: "England",
                performance: {
                    appearances: 4,
                    goals: 1,
                    assists: 1
                },
                discipline: {
                    yellowCards: 0,
                    secondYellows: 0,
                    redCards: 0
                }
            },
        ]
    },
    {
        name: "Lamine Yamal",
        age: "16",
        role: "Right Winger",
        imageUrl: Lamine,
        achievement: "Growing up through the youth ranks of La Masia",
        clubImg: shrewsburyImg,
        clubName: "FC Barcelona, Spain",
        contractExpires: "30th June 2027",
        lastContract: "4th April 2023",
        citizenships: "Spain",
        injuryUpdate: "Lamine Yamal is recovering from a minor calf strain, expected to return in 2 weeks.",
        newsLogo: bbc,
        newsName: "BBC News",
        playerTransferHistory: [
            {
                club: "Chester",
                clubImg: usclub,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Wingate & Finchley FC",
                clubImg: LiverpoolImg,
                season: "14/15",
                transferType: "Free transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: fcpImg,
                season: "17/18",
                transferType: "End of loan"
            },
            {
                club: "Telford United",
                clubImg: usclub,
                season: "18/19",
                transferType: "Free transfer"
            },
            {
                club: "Shrewsbury Town",
                clubImg: shrewsburyImg,
                season: "19/20",
                transferType: "Undisclosed"
            },
            {
                club: "Leamington",
                clubImg: LiverpoolImg,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Grays Athletic FC",
                clubImg: brewersImg,
                season: "14/15",
                transferType: "Free transfer"
            },
            {
                club: "Halesowen Town",
                clubImg: brewersImg,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Ilkeston",
                clubImg: fcpImg,
                season: "15/16",
                transferType: "Free transfer"
            },
            {
                club: "Solihull Moors",
                clubImg: brewersImg,
                season: "16/17",
                transferType: "Loan transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: shrewsburyImg,
                season: "16/17",
                transferType: "End of loan"
            },
            {
                club: "Crewe Alexandra",
                clubImg: LiverpoolImg,
                season: "15/16",
                transferType: "Undisclosed"
            },
            {
                club: "Crewe Alexandra",
                clubImg: shrewsburyImg,
                season: "17/18",
                transferType: "End of loan"
            },
            {
                club: "Crewe Alexandra",
                clubImg: fcpImg,
                season: "17/18",
                transferType: "End of loan"
            }
        ],
        leagueStatistics: [
            {
                leagueName: "La Liga 23/24",
                leagueLogo: LigaLeague,
                country: "Spain",
                performance: {
                    appearances: 10,
                    goals: 3,
                    assists: 2
                },
                discipline: {
                    yellowCards: 1,
                    secondYellows: 0,
                    redCards: 0
                }
            },
            {
                leagueName: "Copa del Rey 23/24",
                leagueLogo: CopaLeague,
                country: "Spain",
                performance: {
                    appearances: 4,
                    goals: 1,
                    assists: 1
                },
                discipline: {
                    yellowCards: 0,
                    secondYellows: 0,
                    redCards: 0
                }
            },
        ]
    },
    {
        name: "Endrick",
        age: "18",
        role: "Striker",
        imageUrl: endrick,
        achievement: "165 goals in 169 games for Palmeiras youth",
        clubImg: RealMadridImg,
        clubName: "Real Madrid, Spain",
        contractExpires: "30th June 2027",
        lastContract: "4th April 2023",
        citizenships: "Brazil",
        injuryUpdate: "Endrick is sidelined with a muscle strain, expected to return in 6 weeks.",
        newsLogo: espn,
        newsName: "ESPN",
        playerTransferHistory: [
            {
                club: "Shrewsbury Town",
                clubImg: shrewsburyImg,
                season: "19/20",
                transferType: "Undisclosed"
            },
            {
                club: "Telford United",
                clubImg: usclub,
                season: "18/19",
                transferType: "Free transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: fcpImg,
                season: "17/18",
                transferType: "End of loan"
            },
            {
                club: "Leamington",
                clubImg: LiverpoolImg,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: shrewsburyImg,
                season: "17/18",
                transferType: "End of loan"
            },
            {
                club: "Chester",
                clubImg: usclub,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: fcpImg,
                season: "17/18",
                transferType: "End of loan"
            },
            {
                club: "Halesowen Town",
                clubImg: brewersImg,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: shrewsburyImg,
                season: "16/17",
                transferType: "End of loan"
            },
            {
                club: "Solihull Moors",
                clubImg: brewersImg,
                season: "16/17",
                transferType: "Loan transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: LiverpoolImg,
                season: "15/16",
                transferType: "Undisclosed"
            },
            {
                club: "Ilkeston",
                clubImg: fcpImg,
                season: "15/16",
                transferType: "Free transfer"
            },
            {
                club: "Grays Athletic FC",
                clubImg: brewersImg,
                season: "14/15",
                transferType: "Free transfer"
            },
            {
                club: "Wingate & Finchley FC",
                clubImg: LiverpoolImg,
                season: "14/15",
                transferType: "Free transfer"
            }
        ],
        leagueStatistics: [
            {
                leagueName: "La Liga 23/24",
                leagueLogo: LigaLeague,
                country: "Spain",
                performance: {
                    appearances: 14,
                    goals: 7,
                    assists: 3
                },
                discipline: {
                    yellowCards: 2,
                    secondYellows: 0,
                    redCards: 0
                }
            },
            {
                leagueName: "Champions League 23/24",
                leagueLogo: UEFA,
                country: "Europe",
                performance: {
                    appearances: 6,
                    goals: 3,
                    assists: 1
                },
                discipline: {
                    yellowCards: 1,
                    secondYellows: 0,
                    redCards: 0
                }
            },
        ]
    },
]


export const Luke_ShawInfo = [
    {
        name: "Luke Shaw",
        age: "24",
        role: "Attacking Midfield",
        imageUrl: Luke_Shaw,
        clubName: "Manchester United, England",
        clubImg: ManchesterImg,
        team: "Manchester United",
        contractExpires: "30th june 2027",
        lastContract: "4th April 2023",
        citizenships: "England",
        playerTransferHistory: [
            {
                club: "Chester",
                clubImg: usclub,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Wingate & Finchley FC",
                clubImg: LiverpoolImg,
                season: "14/15",
                transferType: "Free transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: fcpImg,
                season: "17/18",
                transferType: "End of loan"
            },
            {
                club: "Telford United",
                clubImg: usclub,
                season: "18/19",
                transferType: "Free transfer"
            },
            {
                club: "Shrewsbury Town",
                clubImg: shrewsburyImg,
                season: "19/20",
                transferType: "Undisclosed"
            },
            {
                club: "Leamington",
                clubImg: LiverpoolImg,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Grays Athletic FC",
                clubImg: brewersImg,
                season: "14/15",
                transferType: "Free transfer"
            },
            {
                club: "Halesowen Town",
                clubImg: brewersImg,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Ilkeston",
                clubImg: fcpImg,
                season: "15/16",
                transferType: "Free transfer"
            },
            {
                club: "Solihull Moors",
                clubImg: brewersImg,
                season: "16/17",
                transferType: "Loan transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: shrewsburyImg,
                season: "16/17",
                transferType: "End of loan"
            },
            {
                club: "Crewe Alexandra",
                clubImg: LiverpoolImg,
                season: "15/16",
                transferType: "Undisclosed"
            },
            {
                club: "Crewe Alexandra",
                clubImg: shrewsburyImg,
                season: "17/18",
                transferType: "End of loan"
            },
            {
                club: "Crewe Alexandra",
                clubImg: fcpImg,
                season: "17/18",
                transferType: "End of loan"
            }
        ],
    },
]

export const Alan_VarelaInfo = [
    {
        name: "Alan Varela",
        age: "23",
        role: "Right Winger",
        imageUrl: Alan_Varela,
        clubName: "FC Porto, Argentina",
        clubImg: RealMadridImg,
        team: "FC Porto",
        contractExpires: "30th June 2027",
        lastContract: "4th April 2023",
        citizenships: "Brazil",
        injuryUpdate: "Alan Varela is sidelined with a muscle strain, expected to return in 2 weeks.",
        playerTransferHistory: [
            {
                club: "Grays Athletic FC",
                clubImg: brewersImg,
                season: "14/15",
                transferType: "Free transfer"
            },
            {
                club: "Telford United",
                clubImg: usclub,
                season: "18/19",
                transferType: "Free transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: shrewsburyImg,
                season: "17/18",
                transferType: "End of loan"
            },
            {
                club: "Solihull Moors",
                clubImg: brewersImg,
                season: "16/17",
                transferType: "Loan transfer"
            },
            {
                club: "Leamington",
                clubImg: LiverpoolImg,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: fcpImg,
                season: "15/16",
                transferType: "Undisclosed"
            },
            {
                club: "Ilkeston",
                clubImg: fcpImg,
                season: "15/16",
                transferType: "Free transfer"
            },
            {
                club: "Halesowen Town",
                clubImg: brewersImg,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: shrewsburyImg,
                season: "16/17",
                transferType: "End of loan"
            },
            {
                club: "Chester",
                clubImg: usclub,
                season: "17/18",
                transferType: "Loan transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: fcpImg,
                season: "17/18",
                transferType: "End of loan"
            },
            {
                club: "Wingate & Finchley FC",
                clubImg: LiverpoolImg,
                season: "14/15",
                transferType: "Free transfer"
            },
            {
                club: "Crewe Alexandra",
                clubImg: LiverpoolImg,
                season: "15/16",
                transferType: "Undisclosed"
            },
            {
                club: "Shrewsbury Town",
                clubImg: shrewsburyImg,
                season: "19/20",
                transferType: "Undisclosed"
            }
        ],
        leagueStatistics: [
            {
                leagueName: "La Liga 23/24",
                leagueLogo: LigaLeague,
                country: "Spain",
                performance: {
                    appearances: 14,
                    goals: 7,
                    assists: 3
                },
                discipline: {
                    yellowCards: 2,
                    secondYellows: 0,
                    redCards: 0
                }
            },
            {
                leagueName: "Champions League 23/24",
                leagueLogo: UEFA,
                country: "Europe",
                performance: {
                    appearances: 6,
                    goals: 3,
                    assists: 1
                },
                discipline: {
                    yellowCards: 1,
                    secondYellows: 0,
                    redCards: 0
                }
            },
        ]
    },
]

export const AllTournamentPlayers = [
    {
        name: "Harry Kane",
        clubName: "Bayern Munich",
        leagueName: "Bundesliga",
        age: 30,
        matches: 32,
        assists: 8,
        goals: 36,
    },
    {
        name: "Cristiano Ronaldo",
        clubName: "Al-Nassr",
        leagueName: "Saudi Pro League",
        age: 39,
        matches: 31,
        assists: 11,
        goals: 35,
    },
    {
        name: "Luuk de Jong",
        clubName: "PSV Eindhoven",
        leagueName: "Eredivisie",
        age: 33,
        matches: 34,
        assists: 15,
        goals: 29,
    },
    {
        name: "Viktor Gyökeres",
        clubName: "Sporting CP",
        leagueName: "Liga Portugal",
        age: 26,
        matches: 33,
        assists: 9,
        goals: 29,
    },
    {
        name: "Marcus Haber",
        clubName: "Svay Rieng",
        leagueName: "C. Premier League",
        age: 35,
        matches: 26,
        assists: 6,
        goals: 29,
    },
    {
        name: "Vangelis Pavlidis",
        clubName: "AZ Alkmaar",
        leagueName: "Eredivisie",
        age: 25,
        matches: 34,
        assists: 4,
        goals: 29,
    },
    {
        name: "Aleksandar Mitrovic",
        clubName: "2 clubs",
        leagueName: "Premier League",
        age: 29,
        matches: 29,
        assists: 6,
        goals: 28,
    },
    {
        name: "Serhou Guirassy",
        clubName: "VfB Stuttgart",
        leagueName: "Bundesliga",
        age: 28,
        matches: 28,
        assists: 2,
        goals: 28,
    },
    {
        name: "Kilian Mbappé",
        clubName: "Paris Saint-Germain",
        leagueName: "Ligue 1",
        age: 25,
        matches: 27,
        assists: 5,
        goals: 27,
    },
    {
        name: "Erling Haaland",
        clubName: "Manchester City",
        leagueName: "Premier League",
        age: 23,
        matches: 31,
        assists: 6,
        goals: 27,
    }
];
export const PremierLeaguePlayers = [
    {
        name: "Erling Haaland",
        clubName: "Manchester City",
        leagueName: "Premier League",
        age: 23,
        matches: 27,
        assists: 7,
        goals: 27,
    },
    {
        name: "Harry Kane",
        clubName: "Tottenham Hotspur",
        leagueName: "Premier League",
        age: 30,
        matches: 32,
        assists: 8,
        goals: 24,
    },
    {
        name: "Marcus Rashford",
        clubName: "Manchester United",
        leagueName: "Premier League",
        age: 26,
        matches: 31,
        assists: 5,
        goals: 20,
    },
    {
        name: "Mohamed Salah",
        clubName: "Liverpool",
        leagueName: "Premier League",
        age: 31,
        matches: 29,
        assists: 10,
        goals: 19,
    },
    {
        name: "Ivan Toney",
        clubName: "Brentford",
        leagueName: "Premier League",
        age: 27,
        matches: 30,
        assists: 4,
        goals: 18,
    },
    {
        name: "Bukayo Saka",
        clubName: "Arsenal",
        leagueName: "Premier League",
        age: 22,
        matches: 28,
        assists: 9,
        goals: 17,
    },
    {
        name: "Gabriel Martinelli",
        clubName: "Arsenal",
        leagueName: "Premier League",
        age: 22,
        matches: 30,
        assists: 6,
        goals: 16,
    },
    {
        name: "Kevin De Bruyne",
        clubName: "Manchester City",
        leagueName: "Premier League",
        age: 32,
        matches: 25,
        assists: 15,
        goals: 15,
    },
    {
        name: "Bruno Fernandes",
        clubName: "Manchester United",
        leagueName: "Premier League",
        age: 29,
        matches: 30,
        assists: 12,
        goals: 14,
    },
    {
        name: "Son Heung-min",
        clubName: "Tottenham Hotspur",
        leagueName: "Premier League",
        age: 31,
        matches: 28,
        assists: 8,
        goals: 13,
    }
];
export const BundesligaPlayers = [
    {
        name: "Harry Kane",
        clubName: "Bayern Munich",
        leagueName: "Bundesliga",
        age: 30,
        matches: 32,
        assists: 8,
        goals: 36,
    },
    {
        name: "Serhou Guirassy",
        clubName: "VfB Stuttgart",
        leagueName: "Bundesliga",
        age: 28,
        matches: 28,
        assists: 2,
        goals: 22,
    },
    {
        name: "Robert Lewandowski",
        clubName: "Borussia Dortmund",
        leagueName: "Bundesliga",
        age: 35,
        matches: 30,
        assists: 5,
        goals: 21,
    },
    {
        name: "Christopher Nkunku",
        clubName: "RB Leipzig",
        leagueName: "Bundesliga",
        age: 26,
        matches: 27,
        assists: 10,
        goals: 20,
    },
    {
        name: "Thomas Müller",
        clubName: "Bayern Munich",
        leagueName: "Bundesliga",
        age: 34,
        matches: 29,
        assists: 15,
        goals: 18,
    },
    {
        name: "Marco Reus",
        clubName: "Borussia Dortmund",
        leagueName: "Bundesliga",
        age: 34,
        matches: 28,
        assists: 7,
        goals: 16,
    },
    {
        name: "Florian Wirtz",
        clubName: "Bayer Leverkusen",
        leagueName: "Bundesliga",
        age: 20,
        matches: 30,
        assists: 12,
        goals: 15,
    },
    {
        name: "Leroy Sané",
        clubName: "Bayern Munich",
        leagueName: "Bundesliga",
        age: 28,
        matches: 28,
        assists: 8,
        goals: 14,
    },
    {
        name: "Jamal Musiala",
        clubName: "Bayern Munich",
        leagueName: "Bundesliga",
        age: 21,
        matches: 29,
        assists: 10,
        goals: 13,
    },
    {
        name: "André Silva",
        clubName: "RB Leipzig",
        leagueName: "Bundesliga",
        age: 28,
        matches: 27,
        assists: 6,
        goals: 12,
    }
];

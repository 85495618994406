import { NavLink, useLocation } from 'react-router-dom';
import LatestNewsCard from '../templates/LatestNewsCard';

export default function UserDashboardMenu({ playerWatchlistCount }) {
    const location = useLocation();
    return (
        <div className="p-2">
            <div className="fw-semi-bold small mb-5"><i className="fa-light fa-grid me-2"></i>My dashboard</div>
            <div className="mb-5">
                <p className="fw-semi-bold small text-neutral-gray-700">Your preferences</p>
                <div className="fw-semi-bold border-start border-dark-mode-200 p-2 pb-1 ">
                    <ul className="list-unstyled  mb-0">
                        <li className="nav-item mb-1">
                            <NavLink to={'/newsfeed/managewatchlist'} activeclassname="active" className="btn p-2  fw-normal fw-active-semi-bold   bg-transparent bg-active-dark-mode-200 bg-hover-dark-mode-200 d-flex justify-content-between align-items-center show-item-on-hover-or-active text-decoration-none border-0 small " >
                                <div>Manage your watchlist</div>
                                <div>
                                    <div className="item">
                                        <i className="fa-light fa-angle-right"></i>
                                    </div>
                                </div>
                            </NavLink>
                        </li>
                        <li className="nav-item mb-1">
                            <NavLink to={'/savednews'} activeclassname="active" className="btn p-2  fw-normal fw-active-semi-bold   bg-transparent bg-active-dark-mode-200 bg-hover-dark-mode-200 d-flex justify-content-between align-items-center show-item-on-hover-or-active text-decoration-none border-0 small " >
                                <div>Saved for later</div>
                                <div>
                                    <div className="item">
                                        <i className="fa-light fa-angle-right"></i>
                                    </div>
                                </div>
                            </NavLink>
                        </li>

                    </ul>
                </div>
                {location.pathname === '/newsfeed/managewatchlist' && playerWatchlistCount > 0 && (
                    <LatestNewsCard />
                )}
            </div>
        </div>
    )
}
import { intervalToDuration } from 'date-fns'

const HelperFunctions = () => {

    const kFormatter = (num) => {
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
    }
    const dateFormatter = (datestr) => {

        const suffix = {
            one: 'st',
            two: 'nd',
            few: 'rd',
            other: 'th',
        };

        var theDate = new Date(datestr);

        return new Intl.DateTimeFormat('en-GB', { day: 'numeric' }).format(theDate) + suffix[new Intl.PluralRules('en-GB', { type: 'ordinal' }).select(theDate.getDate())] + ' ' + new Intl.DateTimeFormat('en-GB', { month: 'long' }).format(theDate) + ' ' + theDate.getFullYear();
    }
    const ageFormatter = (dateOfBirth) => {
        const dob = new Date(dateOfBirth);
        const diffMs = Date.now() - dob.getTime();
        const ageDt = new Date(diffMs);
        return Math.abs(ageDt.getUTCFullYear() - 1970);
    };

    const durationFormatter = (timeStamp) => {

        let seconds = new Date().valueOf() - timeStamp;
        let duration = intervalToDuration({ start: 0, end: seconds });

        if (duration.year) {
            return `${duration.year}y`;
        } else if (duration.months) {
            return `${duration.months}M`;
        } else if (duration.days) {
            return `${duration.days}d`;
        } else if (duration.hours) {
            return `${duration.hours}h`;
        } else if (duration.minutes) {
            return `${duration.minutes}m`;
        } else {
            return `${duration.seconds}s`;
        }

    };


    const statsFormatter = (stat) => {
        let sentence = stat.split(/(?=[A-Z])/).join(' ').toLowerCase();
        return sentence.charAt(0).toUpperCase() + sentence.slice(1);
    };

    const stringTruncate = (str, n = 100) => {
        if (!str) return ''; // Return an empty string if str is undefined or null
        return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
    };

    function formatValue(value) {
        if (value === undefined || value === null) {
            return 'N/A';
        }
        if (value === 0) {
            return '0';
        }
        if (typeof value === 'number') {
            return value.toLocaleString();
        }
        return value;
    }

    const valueToQueryStringParam = (value) => {
        if (typeof value === 'string' || typeof value === 'number') {
            return value.toString();
        } else {
            return JSON.stringify(value)
        }

    }

    const buildQueryStringFromFilterObj = (filterObj, excludeOrigin) => {

        let stateString = '';
        let foundProperties = [];

        if (0 < window.location.search.length) {

            const params = new URLSearchParams(window.location.search);

            //loop through existing query string params and modify them if appropiate
            for (const [key, value] of params.entries()) {

                if (key in filterObj) {
                    stateString = stateString + key + "=" + valueToQueryStringParam(filterObj[key]) + '&';
                    foundProperties.push(key);
                } else {
                    stateString = stateString + key + "=" + value + '&';
                }
            }

        }

        //now add those properties that are not present in the querystring
        for (const property in filterObj) {
            if (-1 === foundProperties.indexOf(property)) {
                stateString = stateString + property + "=" + valueToQueryStringParam(filterObj[property]) + '&';
            }
        }


        if (0 < stateString.length) {
            stateString = '?' + stateString.substring(0, stateString.length - 1);
        } else {
            if (!excludeOrigin)
                stateString = window.location.href.split('?')[0];
        }

        return stateString;

    }
    const firstNameExtracted = (first_name) => {
        return first_name ? first_name.split(' ')[0] : '';
    }



    return { kFormatter, dateFormatter, stringTruncate, ageFormatter, statsFormatter, formatValue, durationFormatter, buildQueryStringFromFilterObj, firstNameExtracted };

}
export default HelperFunctions;
import NewsFeedNewsSummaryCard from "../../templates/NewsFeed/NewsFeedNewsSummaryCard";
import React, { useEffect, useState, useCallback } from 'react';
import { authenticationService } from '../../services/authenticationService';
import { apiService } from '../../services/apiService';
import Loader from '../../templates/Loader';

export default function NewsSummariesListing({ searchParameters, onError }) {

    const [initiallyLoaded, setInitiallyLoaded] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [results, setResults] = useState([]);
    const [resultsTotal, setResultsTotal] = useState(0);
   
    const [visibleCards, setVisibleCards] = useState(3);

    const performSearch = useCallback(async () => {

        setVisibleCards(3);
        setLoadingData(true);         

        apiService.apiGet(`${process.env.REACT_APP_API_URL}/mynews/summaries?search_keyword=${searchParameters.question}&page=1`)
            .then((resp) => {
                setResults(resp.summaries.filter((i) => i.text !==''));
            
                setInitiallyLoaded(true);
                setLoadingData(false);
                setResultsTotal(resp.summaries.filter((i) => i.text !=='').length);
            },
                error => {
                    setResults([]);
                    setLoadingData(false);
                    setInitiallyLoaded(false);
                    if ('401' === error.message) {
                        authenticationService.logout();
                    } else {
                        onError(error.message + ' error');
                    }
                });
       

    }, [onError, searchParameters]);

    const loadMoreHandler = () => {
        setVisibleCards((prev) => prev + 3);
    };

    const viewResults = results.slice(0, visibleCards);

   
    useEffect(() => {
        performSearch();
    }, [performSearch]);

    return (
        <div className='mb-4'>
            {!initiallyLoaded ? (
                <Loader />
            ) : (
                <div className="">
                    { 0 < viewResults.length && <p className="large fw-semi-bold mb-5 ">Your news summary</p>}
                    {viewResults.map(function (object, i) {
                        return <div key={i} className="mb-3">
                            <NewsFeedNewsSummaryCard data={object} />
                        </div>
                    })
                    }

                    {loadingData && <Loader />}
                    {visibleCards < resultsTotal && (
                        <button
                            onClick={loadMoreHandler}
                            className="btn btn-md bg-dark-mode-300  w-100  small flex-0 fw-semi-bold position-relative"
                            disabled={loadingData}
                        >
                            Load more {loadingData && <span className="spinner-border spinner-border-sm position-absolute top-50 start-50 text-white mx-n2 mt-n2" role="status" aria-hidden="true"></span>}
                        </button>
                    )}

                </div>
            )
            }
        </div>
    )
}

import React, { useState, useEffect } from 'react';
import { apiService } from '../../services/apiService';
import Loader from '../../templates/Loader';
import { authenticationService } from '../../services/authenticationService';
import { Link } from 'react-router-dom';
import NewsFeedListing from "./NewsFeedListing";
import NoSearchTerms from "../../templates/NewsFeed/NoSearchTerms";
import HelperFunctions from '../../helpers/HelperFunctions';


export default function NewsFeedComponent({ props, onError }) {

    const [initiallyLoaded, setInitiallyLoaded] = useState(false);
    const [hasFollowedPlayers, setHasFollowedPlayers] = useState(false);
    const [searchPhrase, setSearchPhrase] = useState('');
    const [searchParameters, setSearchParameters] = useState(
        { 'question': '' }
    );
    const { buildQueryStringFromFilterObj } = HelperFunctions();

    useEffect(() => {
        if (0 < window.location.search.length) {

            const params = new URLSearchParams(window.location.search);

            if (params.has('question')) {
                setSearchParameters((prevParams) => ({
                    ...prevParams,
                    'question': params.get('question')
                }));
                setSearchPhrase(params.get('question'));
            }

        }
    }, []);

    useEffect(() => {
        apiService.apiGet(process.env.REACT_APP_API_URL + '/mynews/watchlist')
            .then((data) => {
                setHasFollowedPlayers(0 < data.length);
                setInitiallyLoaded(true)
            },
                error => {
                    if ('401' === error.message) {
                        authenticationService.logout();
                    } else {
                        onError(error.message + ' error');
                    }
                })

    }, [onError]);

    useEffect(() => {
        if (searchParameters.question) {
            let searchParametersCombined = { ...searchParameters };
            let stateString = buildQueryStringFromFilterObj(searchParametersCombined);
            window.history.replaceState({}, '', stateString);
        }
    }, [searchParameters, buildQueryStringFromFilterObj]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setSearchParameters((prevParams) => ({
            ...prevParams,
            'question': searchPhrase,

        }));
    }

    return (
        <div> {!initiallyLoaded ? (            
            <div className="ratio ratio-16x9">
                <div className='center-content'>
                    <Loader />
                </div>
            </div>            
        ) : (<>
            {hasFollowedPlayers ?
                <><div className="d-flex justify-content-between">
                    <h4 className="fw-bold">Search the news</h4>
                    <Link to="/newsfeed/managewatchlist" className="large" title="Manage your watchlist"><i className="fa-light fa-plus"></i></Link>
                </div>
                    <div className='bg-dark-mode-700 p-3 mb-3 rounded-3'>
                        <form onSubmit={handleSubmit} >
                            <div className="justify-content-between align-items-center  d-flex mb-3">
                                <input type="text" className=" dark search-query text-white bg-dark-mode-200 border-1 border-dark-mode-50  rounded-2 form-control form-control-lg me-3" placeholder="Search the news.." required value={searchPhrase} onChange={(e) => setSearchPhrase(e.target.value)} />
                                <button type="submit" className="btn btn-lg bg-fluoro-green-500 text-decoration-none small fw-semi-bold  rounded-2 flex-0">Search</button>
                            </div>
                            <div className="btn-group focus-dark active w-100 mt-5 d-none">
                                <input type="text" className="py-25 px-2 text-white bg-dark-mode-50 border-1 border-start-0 border-end-0 border-neutral-gray-900 rounded-pill form-control form-control-lg  rounded-top-end-0 rounded-bottom-end-0" placeholder="Search the news.." required value={searchPhrase} onChange={(e) => setSearchPhrase(e.target.value)} />
                                <button type="submit" className="btn btn-link border-1 border-neutral-gray-900 rounded-pill rounded-top-start-0 rounded-bottom-start-0 border-start-0 bg-dark-mode-50  small position-relative"><i className="fa-solid fa-magnifying-glass text-white"></i></button>
                            </div>
                        </form>
                        <p className='text-neutral-grey-700 small mb-0 text-center'>Look for players or keywords across all sources.</p>
                    </div>
                   
                 
                    <NewsFeedListing searchParameters={searchParameters} onError={onError} />
                  
                </>
                : <NoSearchTerms />

            }
        </>)}
        </div>
    );
}

import React, { useState } from 'react'
import UserDashboardMenu from '../../components/UserDashboardMenu';
import Modal from '../../templates/Modal';
import SavedNews from '../../components/NewsFeed/SavedNews';

const ManageSavedNews = () => {
  const [modalMessage, setModalMessage] = useState(false);
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="d-md-flex">
      <div className="d-none d-md-block flex-grow-0 w-px-262 min-w-px-262 px-2">
        <UserDashboardMenu />
      </div>
      <div className=" flex-grow-1">
        <div className=" mb-0">
          {showModal &&
            <Modal message={modalMessage} onClose={() => setShowModal(false)} />
          }
          <div className="mb-md-7 mb-5">
            <SavedNews onError={(message) => { setShowModal(true); setModalMessage(message) }} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManageSavedNews
import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../helpers/handleResponse';


const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value },
    currentUserSubject
};

function login(email, password) {

    let data = new FormData();
    data.append('email',email);
    data.append('password',password);

    const requestOptions = {
        method: 'POST',
        //headers: { 'Content-Type': 'application/json' },
        body: data
    };

    return fetch(process.env.REACT_APP_API_URL + '/login', requestOptions)
        .then(res =>
            {
                if(res.status!==200 && res.status!==201)
                {
                    throw new Error(res.status)
                } else {
                    return res;
                }
            }
        )
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);

            return user;
        });
}


function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}

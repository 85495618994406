import React from 'react'
import { PlayerSVG, TeamSVG } from './SvgIcons';

const WatchlistPlayerCard = ({ data = {}, onFollowingClick }) => {
    const { first_name, last_name, image, } = data;
    const fullName = `${first_name || ''} ${last_name || ''}`.trim();
    const team_image = data.team?.image || data.team_image;
    const team_name = data.team?.name || data.team || data.team_name;
    const first_name_extracted = first_name?.split(' ')[0];


    return (
        <>
            <div className={`h-100 d-flex flex-column justify-content-between align-items-center px-3 px-md-0 w-100`}>
                    <div className='d-flex flex-row w-100 mb-0'>
                        {image ?
                            <img className={`object-fit-cover disc-65 rounded-circle bg-white mb-0 me-3`} src={image} alt={fullName} />
                            :
                            <PlayerSVG className='disc-65 mb-0 me-3 ' />
                        }

                        <div className='d-flex justify-content-between align-items-center w-100'>
                            <div className='d-flex flex-column align-items-start'>
                                <p className="mb-0 large fw-normal text-center">
                                    {first_name_extracted} <strong className="fw-semi-bold">{last_name}</strong>
                                </p>
                                <div className='d-flex justify-content-between align-items-center w-100 mt-2'>
                                    <div className='center-content rounded-2 rounded-top-start-0 rounded-top-end-0'>
                                        {team_image ?
                                            <img className="object-fit-cover disc-35 rounded-circle me-2" src={team_image} alt={team_name || "Manchester United"} />
                                            :
                                            <TeamSVG />
                                        }
                                        <p className="mb-0 small">{team_name || "Manchester United, England"}</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='text-white border-1 border-zero-dark-100 small fw-normal px-2 py-1 text-center bg-zero-dark-100 rounded-2' onClick={() => onFollowingClick(data)}>
                                    Following
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </>
    )
}

export default WatchlistPlayerCard
export default function Modal({ onClose, message, modalSize }) {

    return (
        <><div className="modal-backdrop fade show"></div>
            <div className="modal fade show" tabIndex="-1" aria-labelledby="saveSearchModalLabel" style={{ display: 'block' }} aria-hidden="false">
                <div className={`modal-dialog ${modalSize} modal-dialog-centered`}>
                    <div className="modal-content position-relative">
                        <button type="button" className="position-absolute z-index-1 end-0 top-0 disc-4 bg-neutral-gray-900 disc-md-45 btn-close mt-n3 me-md-n35 me-n3" onClick={onClose} aria-label="Close"></button>
                        <div className="modal-body">
                            <div className="p-3">
                                {message}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>


    );
}
import HelperFunctions from '../helpers/HelperFunctions';
import CountryFlag from 'react-country-flag';


export default function MorePlayerInformation({ props, playerinfo = {} }) {

    const { dateFormatter } = HelperFunctions();
    const expiredate = playerinfo?.current_contract ? dateFormatter(playerinfo?.current_contract?.date_ending) : "N/A";
    // const lastExtension = playerinfo.current_contract?.date_extension || "N/A";
    const countryFlag = playerinfo?.nationalities[0]?.code2 || "GB";
    const citizenships = playerinfo?.nationalities[0]?.name;
    return (
        <div>
            <p className="large d-md-block d-none fw-semi-bold">More player information</p>
            <p className="small d-md-none d-block fw-semi-bold mb-2">More player information</p>

            <div className="d-grid grid-2-cols bg-dark-mode-300 grid-gap-0 rounded-2">
                <div className="fw-semi-bold px-md-4 px-2half py-md-3 py-2 d-none d-md-flex align-items-center">Contract expires </div>
                <div className="fw-semi-bold px-md-4 px-2half py-md-3 py-2 extra-small d-flex align-items-center d-md-none">Contract expires </div>
                <div className="fw-normal px-md-4 px-2half py-md-3 py-2 d-none d-md-flex align-items-center">{expiredate}</div>
                <div className="fw-normal px-md-4 px-2half py-md-3 py-2 extra-small d-flex align-items-center d-md-none">{expiredate}</div>

                {/* <div className="fw-semi-bold px-md-4 px-2half py-md-3 py-2 d-none d-md-flex align-items-center ">Last contract extension</div>
                <div className="fw-semi-bold px-md-4 px-2half py-md-3 py-2 extra-small d-flex align-items-center d-md-none ">Last contract extension</div>
                <div className="fw-normal px-md-4 px-2half py-md-3 py-2 d-none d-md-flex align-items-center ">{lastExtension}</div>
                <div className="fw-normal px-md-4 px-2half py-md-3 py-2 extra-small d-flex align-items-center d-md-none ">{lastExtension}</div> */}


                <div className="fw-semi-bold px-md-4 px-2half py-md-3 py-2 d-none d-md-flex align-items-center bg-dark-mode-500 rounded rounded-top-left-0 rounded-top-right-0 rounded-bottom-right-0">Citizenships</div>
                <div className="fw-semi-bold px-md-4 px-2half py-md-3 py-2 extra-small d-flex align-items-center d-md-none bg-dark-mode-500 rounded rounded-top-left-0 rounded-bottom-right-0">Citizenships</div>
                <div className="fw-normal px-md-4 px-2half py-md-3 py-2 d-none d-md-flex align-items-center bg-dark-mode-500 rounded rounded-bottom-left-0 rounded-top-right-0 rounded-bottom-left-0 rounded-top-left-0"><CountryFlag
                    countryCode={countryFlag}
                    svg
                    className="disc-4 me-2"
                    title={citizenships}
                />{citizenships}</div>
                <div className="fw-normal px-md-4 px-2half py-md-3 py-2 extra-small d-flex align-items-center d-md-none bg-dark-mode-500 rounded rounded-bottom-left-0 rounded-bottom-left-0 rounded-top-left-0"><CountryFlag
                    countryCode={countryFlag}
                    svg
                    className="disc-4 me-2"
                    title={citizenships}
                />{citizenships}</div>

            </div>
        </div >
    );
}


import React, { useState } from "react";
import ScoutAssistBasicSearch from "../components/ScoutAssistBasicSearch";
import Breadcrumb from '../templates/Breadcrumb';
import Modal from "../templates/Modal";

function ScoutAssistBasic() {


    const [modalMessage, setModalMessage] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const breadcrumbItems = [
        { label: 'Home', to: '/' },
        { label: 'Scout assist basic' }
    ];
    return <div>
        {showModal &&
            <Modal message={modalMessage} onClose={() => setShowModal(false)} />
        }
        <Breadcrumb items={breadcrumbItems} />
        <ScoutAssistBasicSearch  onError={(message) => { setShowModal(true); setModalMessage(message) }} />
    </div>
}

export default ScoutAssistBasic;

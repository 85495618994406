import AssociatedCountryCard from "../templates/AssociatedCountryCard";
import PlayerCard from "../templates/PlayerCard";
import AssociatedTeamsCard from "../templates/AssociatedTeamsCard";
import image1 from '../images/playerImage-lg.png'

export default function PlayerTeamInfo({ props }) {


    return (
        <div>
            <div className="d-flex mb-2">
                <div className="flex-grow-1">
                    <h2 className="fw-extra-bold">Luke Shaw gives injury update following his injury in February.</h2>
                </div>
                <div className="flex-grow-0  w-px-md-320 min-w-px-md-320">
                    <i className="fa-light fa-share-nodes text-fio-error-100 fio-fs-4 float-end"></i>
                </div>
            </div>
            <div className="d-md-flex">
                <div className="flex-grow-1 mb-md-0 mb-5">
                    <div>
                        <img src={image1} alt="wedr" className="w-100 h-100 mb-2" />
                    </div>
                    <p className="py-3 mb-0 fw-normal d-md-block d-none">This has definitely been the most frustrating season for me, I have had set back after set back and have worked so hard to get back to fitness but things just haven’t gone my way. I pushed so hard to get back before the end of the season and the final but some things just aren’t meant to be.
                    </p>
                    <p className="py-3 mb-0 fw-normal small d-md-none d-block">This has definitely been the most frustrating season for me, I have had set back after set back and have worked so hard to get back to fitness but things just haven’t gone my way. I pushed so hard to get back before the end of the season and the final but some things just aren’t meant to be.
                    </p>
                    <p className="fw-normal d-md-block d-none">The reality is I am not 100 per cent fit for either right now but I am doing everything I can and constantly working to be. I have been at this club for 10 years through highs and lows and for anyone to question my loyalty is obviously frustrating.
                    </p>
                    <p className="fw-normal small d-md-none d-block">The reality is I am not 100 per cent fit for either right now but I am doing everything I can and constantly working to be. I have been at this club for 10 years through highs and lows and for anyone to question my loyalty is obviously frustrating.
                    </p>
                </div>
                <div className="flex-grow-0 w-px-md-320 min-w-px-md-320 ms-md-4 ms-0">
                    <div className="mb-5">
                        <p className="mb-3">Associated players</p>
                        <div className="d-flex flex-column flex-none flex-md-auto me-1half me-md-0 cursor-pointer w-md-100 bg-dark-mode-300 rounded-2  position-relative text-decoration-none  pt-2half pt-md-4 translate-icon-on-hover">
                            <PlayerCard />
                        </div>
                    </div>
                    <div className="mb-3">
                        <p className="mb-3">Associated teams</p>
                        <AssociatedTeamsCard />
                    </div>
                    <div className="mb-3">
                        <AssociatedCountryCard />
                    </div>
                </div>
            </div>
        </div>
    );
}

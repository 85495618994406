import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { authenticationService } from '../../services/authenticationService';
import { apiService } from '../../services/apiService';
import Loader from '../../templates/Loader';
import SavedNewsCard from '../../templates/SavedNewsCard';
import Pagination from '../../templates/Pagination';

const SavedNews = ({ onError }) => {

    const [savedNewsData, setSavedNewsData] = useState([]);
    const [player, setplayer] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [resultsTotal, setResultsTotal] = useState(0);
    const [pagesTotal, setPagesTotal] = useState(1);
    const [pageSize, setPageSize] = useState(8);
    const [page, setPage] = useState(1);
    const [visibleCards, setVisibleCards] = useState(8);

    useEffect(() => {
        apiService.apiGet(process.env.REACT_APP_API_URL + '/mynews/news')
            .then((data) => {
                console.log(data, "data")
                setSavedNewsData(data.news);
                setResultsTotal(data.news.length);
                setPagesTotal(Math.ceil(data.news.length / pageSize));
                setLoadingData(false);
            })
            .catch((error) => {
                if ('401' === error.message) {
                    authenticationService.logout();
                } else {
                    onError(error.message + ' error');
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize]);

    useEffect(() => {
        apiService.apiGet(process.env.REACT_APP_API_URL + '/mynews/watchlist')
            .then((data) => {
                setplayer(data.length);
            },
                error => {
                    if ('401' === error.message) {
                        authenticationService.logout();
                    } else {
                        onError(error.message + ' error');
                    }
                })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRemoveNews = (newsId) => {
        setSavedNewsData((prev) => prev.filter((item) => item.id !== newsId));
    };

    const pageSizeEventHandler = (page_size) => {
        setPageSize(page_size);
        setPage(1);
    };

    const paginationEventHandler = (page) => {
        setPage(parseInt(page, 10));
    };

    useEffect(() => {
        setPagesTotal(Math.ceil(resultsTotal / pageSize));
    }, [resultsTotal, pageSize]);

    const loadMoreHandler = () => {
        setVisibleCards((prev) => prev + 8);
    };

    const currentPageResults = savedNewsData.slice((page - 1) * pageSize, page * pageSize);
    const mobileViewResults = savedNewsData.slice(0, visibleCards);

    return (
        <div>
            <div className='d-flex align-items-center justify-content-between mb-4'>
                <h4 className="large bold mb-0">Saved for later</h4>
                {player > 0 && <Link to={'/newsfeed'} className="small flex-0 fw-semi-bold rounded-2" >View news</Link>}
            </div>
            {
                loadingData ? (
                    <div className="ratio ratio-21x9">
                        <div className='center-content'>
                            <Loader />
                        </div>
                    </div>
                ) : (
                    <>
                        {savedNewsData.length > 0 ? (
                            <>
                                <div className='mb-3 d-md-block d-none'>
                                    <div className="d-grid grid-gap-3 grid-md-2-cols position-relative">
                                        {currentPageResults.map((value, index) => (
                                            <div key={index} className={'bg-dark-mode-300 d-flex mb-0 rounded-3 position-relative px-5 pt-5 pb-3 w-100'}>
                                                <SavedNewsCard data={value} onRemoveNews={handleRemoveNews} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className='mb-3 d-md-none d-block'>
                                    <div className="d-grid-md grid-gap-3 grid-md-2-cols position-relative">
                                        {mobileViewResults.map((value, index) => (
                                            <div key={index} className={'bg-dark-mode-300 d-flex mb-25 rounded-3 position-relative px-3 pt-3 pb-2 w-100'}>
                                                <SavedNewsCard data={value} onRemoveNews={handleRemoveNews} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {visibleCards < resultsTotal && (
                                    <button onClick={loadMoreHandler} className="btn btn-md bg-fluoro-green-500 d-md-none d-block w-100 w-md-auto text-decoration-none small flex-0 fw-semi-bold mt-3">
                                        Load more
                                    </button>
                                )}
                                <div className='d-md-flex d-none justify-content-end'>
                                    <Pagination onPageSizeChange={pageSizeEventHandler} onItemClick={paginationEventHandler} page={page} pages_total={pagesTotal} page_size={pageSize} />
                                </div>
                            </>
                        ) : (
                            <>No saved news</>
                        )}

                    </>
                )
            }
        </div>
    )
}

export default SavedNews
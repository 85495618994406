import React, { useState } from 'react';
import HelperFunctions from '../helpers/HelperFunctions';

export default function PlayerCardTypeahead({ data, watchlistplayer, onSelect }) {
    const { firstNameExtracted } = HelperFunctions();
    const { firstName, lastName, currentTeam, positions, following } = data;
    const [isFollowed, setIsFollowed] = useState(following === 1);

    const handleFollowClick = () => {
        if (!isFollowed) {
            setIsFollowed(true);
            onSelect(data);
        }
    };

    return (
        <>
            <div className="px-2half py-3 d-flex justify-content-between align-items-center">
                <div className="d-flex flex-column align-items-start me-1">
                    <p className="d-block mb-0 fw-normal text-center small text-decoration-none text-white">
                        {firstNameExtracted(firstName) || 'Luke'}{' '}
                        <strong className="fw-semi-bold">{lastName || 'Shaw'}</strong>
                    </p>
                    {!watchlistplayer && positions.length > 0 && (
                        <p className="d-block mb-0 extra-small text-neutral-gray-700 text-center">
                            {positions[0].positionName}
                        </p>
                    )}
                    <p className="d-block mb-0 extra-small text-neutral-gray-700 text-center">
                        {currentTeam}
                    </p>
                </div>
                {watchlistplayer && !isFollowed && (
                    <div
                        className="btn text-crayola-red-300 border-1 small fw-normal py-1 px-2half text-center border border-crayola-red-300 rounded-pill badge rounded-pill"
                        onClick={handleFollowClick}
                    >
                        Follow
                    </div>
                )}
                {watchlistplayer && isFollowed && (
                    <div className="text-neutral-gray-800 border-1 small fw-normal py-1 px-2half text-center bg-zero-dark-300 rounded-pill badge rounded-pill">
                        Following
                    </div>
                )}
            </div>
        </>
    );
}

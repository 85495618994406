import React from 'react';
import CountryFlag from 'react-country-flag';
import {TeamSVG} from './SvgIcons';

export default function AssociatedTeamsCard({ team = {} }) {
    const { name, competition, image } = team;
    const countryCode = competition?.country?.code2 || "US";

    return (
        <div className="bg-dark-mode-300 rounded-2 p-2">
            <div className='d-flex flex-column align-items-center w-100'>
                <div className='d-flex flex-row flex-md-column align-items-center w-100'>
                    {!image ?
                        <img className="object-fit-cover disc-55 disc-md-6 rounded-circle mb-0 me-2 me-md-0 bg-white" src={image} alt={name} />
                        :
                        <TeamSVG className='object-fit-cover disc-55 disc-md-6 rounded-circle mb-0 me-2 me-md-0'/>
                    }
                    <div className='d-flex flex-column align-items-start align-items-md-center'>
                        <p className="d-none d-md-block mb-0 large">{name || "Manchester United"}</p>
                        <p className="d-block d-md-none mb-0 small">{name || "Manchester United"}</p>

                        { competition && 
                            <div className="center-content d-flex align-items-center">
                                <CountryFlag
                                    countryCode={countryCode}
                                    svg
                                    className="disc-4"
                                    title={competition?.country?.name}
                                />
                                <p className="mb-0 text-neutral-gray-700 ms-2 fw-normal small">{competition?.name}</p>                            
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

import HelperFunctions from '../helpers/HelperFunctions';

export default function NewsCard({ props, data = {} }) {
    
    const { dateFormatter, stringTruncate} = HelperFunctions();
    const { type, text, source_name, source_url, news_date } = {  ...data };
    
    return (
        <a className="d-flex flex-row h-100 w-100 justify-content-between text-decoration-none" target='_blank' rel='noreferrer' href={source_url}>
            <div className="d-flex flex-column h-100  w-100 justify-content-between">
                <div className="d-flex align-items-center justify-content-start mb-3">
                    <div className="d-flex align-items-center justify-content-start me-2">
                        <img className="object-fit-contain w-px-48 h-px-48 rounded-2 flex-none bg-white object-fit-cover" src={'https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=' + source_url + '&size=64'} alt="icon" />
                    </div>
                    <div>
                        <p className="mb-0 small">{source_name}</p>
                        <p className=" text-neutral-gray-700 mb-0 small">{type !== 'api' ? type : ''}</p>
                    </div>
                </div>
                <p className=" text-neutral-gray-500  d-md-block d-none mb-3">{stringTruncate(text)}</p>
                <p className=" text-neutral-gray-500 small mb-3  d-md-none d-block">{stringTruncate(text)}</p>
                <div className="d-flex justify-content-end align-items-center">                  
                    <div className="small text-neutral-gray-50">{news_date? dateFormatter(news_date) : "25th July 2024"}</div>
                </div>
            </div>
        </a>
    );
}

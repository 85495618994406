
import React from "react";
import DetailComponents from "../components/DetailComponents";

function PlayerDetail() {

    return <div>
        <DetailComponents />
    </div>
}

export default PlayerDetail;

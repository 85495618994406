import NewsCard from "../templates/NewsCard";

export default function PressCards({ props, playerNews }) {


    return (
        <div>
            <p className="large d-md-block d-none fw-semi-bold mb-0">In the press</p>
            <p className="small d-md-none d-block fw-semi-bold mb-2">In the press</p>
            <p className="text-neutral-gray-700 fw-normal d-md-block d-none">{playerNews.length} sources captured</p>
            <div className="d-flex overflow-scroll overflow-md-visible d-grid-md  grid-gap-3 grid-md-4-cols pb-2 pb-md-0 position-relative">
                {playerNews.length > 0 ? (
                    playerNews.map((object, i) => (
                        <div key={i} className="d-flex flex-none flex-md-auto me-1half me-md-0 w-75 cursor-pointer w-md-100 bg-dark-mode-300 rounded-2  position-relative text-decoration-none p-3">
                            <NewsCard data={object} />
                        </div>
                    ))
                ) : (
                    <p>No data available</p>
                )}
            </div>
        </div>
    );
}

import HelperFunctions from '../helpers/HelperFunctions';

export default function InsightCard({ index, insights }) {

    const { stringTruncate} = HelperFunctions();

    
    return (
        <div 
            className={`justify-content-between cursor-pointer align-items-center h-100 mb-3 mb-md-0 ${index < 2 ? 'bg-fluoro-green-500 text-b' : 'bg-dark-mode-300'} rounded-2 position-relative text-decoration-none px-md-3 py-md-3 py-2 px-2half w-100 translate-icon-on-hover ${index < 2 ? 'd-flex' : 'd-none d-md-flex'}`}>
            <div className="d-flex align-items-center">
                <i className={`fa-regular fa-sparkles me-4 ${index < 2 ? 'text-black' : 'text-fluoro-green-600'}`}></i>
                <p className="mb-0 d-none d-md-block">{stringTruncate(insights) || "Pep Guardiola faces full 12-month campaign with Manchester City, while Matz Sels battles for No. 1 spot at Nottingham Forest."}</p>
                <p className="mb-0 extra-small d-block d-md-none">{stringTruncate(insights) || "Pep Guardiola faces full 12-month campaign with Manchester City, while Matz Sels battles for No. 1 spot at Nottingham Forest."}</p>
            </div>
            <div className="ms-4 d-flex flex-column justify-content-end">
                <i className={`icon ${index < 2 ? 'text-black' : 'text-neutral-gray-800'} fa-solid fa-arrow-right small`}></i>
            </div>
        </div>
    );
}

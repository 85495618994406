import { useEffect, useState } from 'react';

import { apiService } from '../services/apiService';
import Loader from '../templates/Loader';
import FeaturedStoryCard from '../templates/FeaturedStoryCard';
import AIInsights from './AIInsights';
import { Modal } from "react-bootstrap";
import UpgradeModal from "../templates/UpgradeModal";
import { authenticationService } from '../services/authenticationService';

export default function InsightSection({ props, onError }) {

    const [loadingData, setLoadingData] = useState(true);
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    useEffect(() => {
        apiService.apiGet(process.env.REACT_APP_API_URL + '/news/list_insights')
            .then((resp) => {
                setData(resp.data);
                setLoadingData(false);
            },
                error => {
                    if ('401' === error.message) {
                        authenticationService.logout();
                    } else {
                        onError(error.message + ' error');
                    }   
                });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Modal size="lg" centered show={showModal} onHide={handleCloseModal}>
                <Modal.Body className='bg-dark-mode-50 rounded-2 position-relative'>
                    <button type="button" className="position-absolute z-index-1 end-0 top-0 disc-4 bg-neutral-gray-900 disc-md-45 btn-close mt-n3 me-md-n35 me-n3"
                        onClick={handleCloseModal} ></button>
                    <div className='p-md-3 mb-0'>
                        <UpgradeModal />
                    </div>
                </Modal.Body>
            </Modal>

            {loadingData ? (
                <Loader />
            ) : (
                <>
                    <div className="d-grid-md grid-2-cols grid-gap-3">
                        <AIInsights onUpgradeClick={handleShowModal} data={data} />
                        <FeaturedStoryCard onUpgradeClick={handleShowModal} data={data[7]} />
                    </div>
                </>
            )}
        </>
    )
}
import React, { useEffect, useState } from 'react';
import { authenticationService } from '../services/authenticationService';
import { useNavigate, useLocation } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';


export default function LoginForm({ props, onError }) {

  const navigate = useNavigate();
  const location = useLocation();

  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (authenticationService.currentUserValue) {
      navigate('/');
    }
  }, [navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {

      event.stopPropagation();
    } else {
      setLoading(true);
      authenticationService.login(email, password).then(
        user => {
          const { from } = location.state || { from: { pathname: "/" } };
          navigate(from);
          setLoading(false); //Stop loading spinner
        },
        error => {
          if ('401' === error.message) {
            onError('Invalid e-mail address or password');
          } else {
            onError(error.message + ' error');
          }
          setLoading(false); //Stop loading spinner           
        }
      );
    }
    setValidated(true);
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Form.Group controlId="validationCustom01">
          <Form.Label>E-mail address</Form.Label>
          <Form.Control size="md" className=' dark  bg-neutral-gray-900 border border-neutral-gray-900 fw-normal' type="email" placeholder="E-mail address" required value={email} onChange={(e) => setEmail(e.target.value)} />
          <Form.Control.Feedback type="invalid">
            Please provide a e-mail address.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group controlId="validationCustom02">
          <Form.Label>Password</Form.Label>
          <Form.Control size="md" className='dark  bg-neutral-gray-900 border border-neutral-gray-900 fw-normal' type="password" placeholder="Password" required value={password} onChange={(e) => setPassword(e.target.value)} />
          <Form.Control.Feedback type="invalid">
            Please provide your password.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <button type="submit" className="py-2 px-4 w-100 btn bg-fluoro-green-500  fw-semi-bold position-relative" disabled={loading}>
        Login {loading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
      </button>
    </Form>
  );

}
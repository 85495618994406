
import HelperFunctions from '../helpers/HelperFunctions';
import PlayerCard from '../templates/PlayerCard';

export default function PlayerTransfer({ props, isInModal, transferInfo }) {
    const { dateFormatter } = HelperFunctions();

    return (
        <>
            <div className='d-flex d-md-none align-items-center mb-4'>
                <div className='w-100'>
                    <div className='d-flex flex-row align-items-center d-flex justify-content-md-start justify-content-between w-100 '>

                        <PlayerCard includeteam={false} data={transferInfo} />
                        <div className='d-flex justfy-content-end align-items-center'>
                            <div className='disc-5 center-content  rounded-circle me-2'>
                                <i className="fa-light fa-share-nodes text-fio-error-100"></i>
                            </div>
                            <div className='disc-5 center-content  bg-secure-blue-700 p-2half rounded-circle'>
                                <i className="fa-regular fa-grid-2-plus "></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p className='fw-semi-bold mb-0  d-md-block d-none'>Transfer history</p>
            <p className='fw-semi-bold mb-0  d-md-none d-block'>Transfer history</p>
            <p className="d-md-block d-none mb-2 small text-neutral-gray-700 text-left w-100">Shows the list of clubs the player has been transferred to.</p>
            <p className="d-md-none d-block mb-2 extra-small text-neutral-gray-700 text-left w-100">Shows the list of clubs the player has been transferred to.</p>
            {transferInfo.transfers.length > 0 ? (
                transferInfo.transfers?.map((item, i) => {
                    const bgClass = isInModal && i % 2 === 0 ? 'bg-dark-mode-300' : isInModal ? 'bg-md-dark-mode-500' : 'bg-dark-mode-300 mb-2';
                    return <div key={i} className={`d-flex justify-content-between ${bgClass} rounded-2 align-items-center px-3 py-2`}>
                        <div className="d-flex align-items-center justify-content-start">
                            <div className="d-flex align-items-center justify-content-start me-2">
                                {item.team_to?.image ?
                                    <>
                                        <img className="object-fit-cover disc-55 disc-md-6 rounded-circle mb-0 me-2 me-md-0 d-md-block d-none" src={item.team_to?.image} alt={item.team_to.name}/>
                                        <img className="object-fit-cover w-px-40 h-px-40 rounded-circle mb-0 me-2 me-md-0 d-md-none d-block" src={item.team_to?.image} alt={item.team_to.name}/>
                                    </>
                                    :
                                    <>
                                        <svg className='object-fit-contain  w-px-58 h-px-58 rounded-2 flex-none d-md-block d-none' width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_1379_8063)">
                                                <path d="M22.8528 6.31207L22.1875 4.7607C20.7682 4.25375 19.2839 4 17.799 4C16.3137 4 14.8278 4.2535 13.4085 4.7602L12.7473 6.31207L17.8 9.11164L22.8528 6.31207ZM9.20685 8.88262L7.52548 9.03191C5.7588 11.3044 4.80005 14.0999 4.80005 16.9792L4.80005 17C4.80005 17.1368 4.80269 17.2732 4.80692 17.4094L6.07391 18.5145L10.2989 14.5703L9.20685 8.88262ZM20.9891 21.0625L22.9442 15.0398L17.7543 11.3125L12.6559 15.0398L14.6272 21.0625H20.9891ZM30.8 16.9797C30.8 14.1004 29.8403 11.3023 28.0721 9.03242L26.4125 8.88313L25.3004 14.5727L29.5259 18.5168L30.7914 17.4103C30.7543 17.2082 30.8 17.1066 30.8 16.9797ZM7.42899 22.6723L7.05372 24.3206C8.75388 26.8145 11.2665 28.6406 14.1631 29.4851L15.6154 28.6223L13.1683 23.3766L7.42899 22.6723ZM22.4313 23.373L19.9842 28.6188L21.435 29.482C24.332 28.636 26.8432 26.8099 28.5443 24.3176L28.1696 22.6692L22.4313 23.373Z" fill="#77828B" />
                                                <path opacity="0.4" d="M30.7899 17.4063C30.7166 19.8773 29.9357 22.2747 28.5413 24.3176L28.1665 22.6693L22.4282 23.3731L19.9811 28.6188L21.4141 29.4739C20.2218 29.8182 18.9934 29.9919 17.7655 29.9919C16.5589 29.9919 15.3529 29.8242 14.1813 29.486L15.6149 28.6197L13.1678 23.374L7.42947 22.6692L7.0542 24.3175C5.66025 22.2766 4.88076 19.8787 4.80713 17.4062L6.07412 18.5112L10.2991 14.567L9.20682 8.88266L7.52545 9.03195C9.03467 7.08754 11.0634 5.61082 13.3754 4.77242L13.3938 4.78605L12.7473 6.31211L17.8 9.11168L22.8528 6.31211L22.1875 4.76074C24.5143 5.59762 26.5547 7.0784 28.0731 9.03195L26.4125 8.88316L25.3004 14.5727L29.5259 18.5169L30.7899 17.4063ZM22.9442 15.0399L17.7543 11.3125L12.6559 15.0399L14.6272 21.0625H20.9891L22.9442 15.0399Z" fill="#77828B" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1379_8063">
                                                    <rect width="26" height="26" fill="white" transform="translate(4.80005 4)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <svg className='object-fit-contain w-px-40 h-px-40 rounded-2 flex-none d-md-none d-block' width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_1379_8063)">
                                                <path d="M22.8528 6.31207L22.1875 4.7607C20.7682 4.25375 19.2839 4 17.799 4C16.3137 4 14.8278 4.2535 13.4085 4.7602L12.7473 6.31207L17.8 9.11164L22.8528 6.31207ZM9.20685 8.88262L7.52548 9.03191C5.7588 11.3044 4.80005 14.0999 4.80005 16.9792L4.80005 17C4.80005 17.1368 4.80269 17.2732 4.80692 17.4094L6.07391 18.5145L10.2989 14.5703L9.20685 8.88262ZM20.9891 21.0625L22.9442 15.0398L17.7543 11.3125L12.6559 15.0398L14.6272 21.0625H20.9891ZM30.8 16.9797C30.8 14.1004 29.8403 11.3023 28.0721 9.03242L26.4125 8.88313L25.3004 14.5727L29.5259 18.5168L30.7914 17.4103C30.7543 17.2082 30.8 17.1066 30.8 16.9797ZM7.42899 22.6723L7.05372 24.3206C8.75388 26.8145 11.2665 28.6406 14.1631 29.4851L15.6154 28.6223L13.1683 23.3766L7.42899 22.6723ZM22.4313 23.373L19.9842 28.6188L21.435 29.482C24.332 28.636 26.8432 26.8099 28.5443 24.3176L28.1696 22.6692L22.4313 23.373Z" fill="#77828B" />
                                                <path opacity="0.4" d="M30.7899 17.4063C30.7166 19.8773 29.9357 22.2747 28.5413 24.3176L28.1665 22.6693L22.4282 23.3731L19.9811 28.6188L21.4141 29.4739C20.2218 29.8182 18.9934 29.9919 17.7655 29.9919C16.5589 29.9919 15.3529 29.8242 14.1813 29.486L15.6149 28.6197L13.1678 23.374L7.42947 22.6692L7.0542 24.3175C5.66025 22.2766 4.88076 19.8787 4.80713 17.4062L6.07412 18.5112L10.2991 14.567L9.20682 8.88266L7.52545 9.03195C9.03467 7.08754 11.0634 5.61082 13.3754 4.77242L13.3938 4.78605L12.7473 6.31211L17.8 9.11168L22.8528 6.31211L22.1875 4.76074C24.5143 5.59762 26.5547 7.0784 28.0731 9.03195L26.4125 8.88316L25.3004 14.5727L29.5259 18.5169L30.7899 17.4063ZM22.9442 15.0399L17.7543 11.3125L12.6559 15.0399L14.6272 21.0625H20.9891L22.9442 15.0399Z" fill="#77828B" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1379_8063">
                                                    <rect width="26" height="26" fill="white" transform="translate(4.80005 4)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </>
                                }
                            </div>
                            <div>
                                <p className="mb-0 fw-normal d-md-block d-none">{item.team_to.name}</p>
                                <p className="mb-0 fw-normal extra-small d-md-none d-block">{item.team_to.name}</p>
                                <p className=" text-neutral-gray-700 mb-0 small fw-normal">
                                    {/* country img here */}
                                </p>
                            </div>
                        </div>
                        <div className='d-flex align-items-end justify-content-center flex-column'>
                            <p className="large fw-semi-bold mb-0">{dateFormatter(item?.date)}</p>
                            <p className="small mb-0 fw-normal">{item.fee}</p>
                        </div>
                    </div>
                })

            ) : (
                <p className='center-content'>History is not available</p>
            )
            }
            <div className='d-flex justify-content-center mt-4'>
                <div className='center-line w-px-58 me-2'></div>
                <p className='text-neutral-gray-700 mb-0 small'>End of transfer history</p>
                <div className='center-line w-px-58 ms-2'></div>
            </div>
        </>
    )
}


export default function Loader() {
    return (
        <div className="grid-loader  w-100" style={{ textAlign: 'center', display: 'block' }}>
            <div className="loader loader-2">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    )
}




import React, { useState } from "react";
import Breadcrumb from '../../templates/Breadcrumb';
import ManageWatchlist from "../../components/NewsFeed/ManageWatchlist";
import UserDashboardMenu from "../../components/UserDashboardMenu"
import Modal from "../../templates/Modal";

function ManagePlayersWatchlist() {
    const [modalMessage, setModalMessage] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [playerWatchlistCount, setPlayerWatchlistCount] = useState(0);
    const breadcrumbItems = [
        { label: 'Home', to: '/' },
        { label: 'News feed', to: '/newsfeed' },
        { label: 'Manage your watchlist' },
    ];

    return (
        <div className="d-md-flex">
            <div className="d-none d-md-block flex-grow-0 w-px-262 min-w-px-262 px-2">
                <UserDashboardMenu playerWatchlistCount={playerWatchlistCount}/>
            </div>
            <div className=" flex-grow-1">
                <div className=" mb-0">
                    {false && <Breadcrumb items={breadcrumbItems} />}
                    {showModal &&
                        <Modal message={modalMessage} onClose={() => setShowModal(false)} />
                    }
                    <div className="mb-md-7 mb-5">
                        <ManageWatchlist onError={(message) => { setShowModal(true); setModalMessage(message) }} onWatchlistCountUpdate={(count) => setPlayerWatchlistCount(count)}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManagePlayersWatchlist;

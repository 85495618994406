import { playerInfo } from "../services/content";
import NewsCard from "../templates/NewsCard";

export default function MoreNews({ props }) {



    return (
        <div>
            <p className="mb-3">More news</p>
            <div className="d-flex overflow-scroll overflow-md-visible d-grid-md  grid-gap-3 grid-md-4-cols  pb-2 pb-md-0  fixed-4-row position-relative">
                {playerInfo.slice(0, 4).map(function (object, i) {
                    return <div key={i} className="d-flex flex-none flex-md-auto me-1half me-md-0 w-75  w-md-100 bg-dark-mode-300 rounded-2  position-relative text-decoration-none p-3">
                        <NewsCard data={object}/>
                    </div>
                })
                }
            </div>
        </div>
    );
}

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Breadcrumb = ({ items,back }) => {
    return (
        <>
            <div className="d-md-flex d-none align-items-center mb-5">
                {items.map((item, index) => (
                    <React.Fragment key={index}>
                        {item.to ? (
                            <Link to={item.to} className="text-decoration-none text-neutral-gray-700 fw-semi-bold">
                                {back && (
                                    <i className="fa-regular fa-arrow-left me-3"></i>
                                )}
                                {item.label}
                            </Link>
                        ) : (
                            <p className="mb-0 text-neutral-gray-700 fw-normal">{item.label}</p>
                        )}
                        {index < items.length - 1 && (
                            <i className="fa-light fa-angle-right mx-2 text-neutral-gray-700"></i>
                        )}
                    </React.Fragment>
                ))}
            </div>
            <div className="d-flex d-md-none mb-4">
                <a href="/" className="d-flex align-items-center text-decoration-none">
                    <i className="fa-regular fa-arrow-left me-3"></i>
                </a>
            </div>
        </>
    );
};

Breadcrumb.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            to: PropTypes.string
        })
    ).isRequired
};

export default Breadcrumb;

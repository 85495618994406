import React, { useState } from "react";
import ScoutAssistSearch from "../components/ScoutAssistSearch";
import Breadcrumb from '../templates/Breadcrumb';
import Modal from "../templates/Modal";
function ScoutAssist() {
    
    const [modalMessage, setModalMessage] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const breadcrumbItems = [
        { label: 'Home', to: '/' },
        { label: 'Scout assist' }
    ];

    return (   
        <div>
            {showModal &&
                <Modal message={modalMessage} onClose={() => setShowModal(false)} />
            }
            <Breadcrumb items={breadcrumbItems} />
            <ScoutAssistSearch  onError={(message) => { setShowModal(true); setModalMessage(message) }}/>    
        </div>     
               
    );
}

export default ScoutAssist;

import React from 'react';
import  { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function NewsImage({ src, alt }) {
    return (
        <LazyLoadImage
            effect="blur"
            wrapperProps={{
                // If you need to, you can tweak the effect transition using the wrapper style.
                style: { transitionDelay: ".1s" },
            }}
            className='w-100'
            src={src} // use normal <img> attributes as props
            alt={alt} />
    )
};


import React from 'react';

const FilterItem = ({ label, isChecked, onChange, count, className }) => {
    return (
        <div className={`form-check mb-2 d-flex align-items-center justify-content-between ${className}`}>
            <div>
                <input
                    type="checkbox"
                    className='form-check-input'
                    id={label}
                    checked={isChecked}
                    onChange={onChange}
                />
                <label htmlFor={label} className="form-check-label ms-2half">
                    {label}
                </label>
            </div>
            <span className=" bg-fluoro-green-200 fw-normal small text-zero-dark-500 rounded-3 fw-semi-bold px-2 py-1">{count}</span>
        </div>
    );
};

export default FilterItem;


import React from "react";
import InformationSources from "../components/InformationSources";
import MoreNews from "../components/MoreNews";
import PlayerTeamInfo from "../components/PlayerTeamInfo";


function PlayerNews() {

    return <div>

        <div className="d-md-flex d-none align-items-center mb-5">
            <a href="/" className="text-decoration-none text-neutral-gray-700 fw-semi-bold">Home</a>
            <i className="fa-regular fa-angle-right mx-2 text-neutral-gray-700" ></i>
            <p className="mb-0 text-neutral-gray-700 fw-semi-bold">News results</p>
        </div>
        <div className="d-flex d-md-none mb-4">
            <a href="/" className="d-flex align-items-center text-decoration-none">
                <i className="fa-regular fa-arrow-left me-3"></i>
            </a>
        </div>
        <div className="mb-md-7 mb-5">
            <PlayerTeamInfo />
        </div>
        <div className="mb-md-7 mb-5">
            <InformationSources />
        </div>
        <div>
            <MoreNews />
        </div>
    </div>
}

export default PlayerNews;

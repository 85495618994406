
export default function AssociatedCountryCard({ props }) {

    return (
        <div className="bg-dark-mode-300 rounded-2 p-2">
            <div className='d-flex flex-row flex-md-column align-items-center w-100'>
                <svg className='object-fit-cover disc-5 disc-md-55 me-3 me-md-0 rounded-2 mb-0 mb-md-2' xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                    <g clipPath="url(#clip0_996_437)">
                        <path d="M-0.307129 0.0871582H18.1274V13.913H-0.307129V0.0871582Z" fill="white" />
                        <path d="M7.8042 0.0871582H10.0163V13.913H7.8042V0.0871582Z" fill="#CE1124" />
                        <path d="M-0.307129 5.89404H18.1274V8.10618H-0.307129V5.89404Z" fill="#CE1124" />
                    </g>
                    <defs>
                        <clipPath id="clip0_996_437">
                            <rect width="18.4345" height="13.8259" fill="white" transform="translate(-0.307129 0.0871582)" />
                        </clipPath>
                    </defs>
                </svg>
                <div className='d-flex flex-column align-items-start align-items-md-center '>
                    <p className="mb-0 fw-normal">England</p>
                    <p className="mb-0 text-neutral-gray-700 fw-normal small">National team</p>
                </div>
            </div>
        </div>
    );
}

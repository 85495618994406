import React, { useState } from 'react'
import HelperFunctions from '../helpers/HelperFunctions';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { apiService } from '../services/apiService';
import PlayerTeamLozenge from './NewsFeed/PlayerTeamLozenge';

const SavedNewsCard = ({ data = {}, onRemoveNews }) => {
    const [showMoreModal, setShowMoreModal] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const { text, source_name, source_url, news_date, recognised_players = [], recognised_team = [] } = { ...data };
    const { stringTruncate, durationFormatter } = HelperFunctions();
    const navigate = useNavigate();

    const handleCardClick = (player) => {
        navigate(`/playerdetail/${player.uuid}`);
    };

    const handleBookmarkClick = () => {
        apiService
            .apiPostJson(`${process.env.REACT_APP_API_URL}/mynews/news/remove`, { news_id: data.id })
            .then(() => {
                onRemoveNews(data.id);
            })
            .catch((error) => {
                console.error('Failed to remove news. ' + error.message);
            });
    };    

    const handleOpenMoreModal = (items) => {
        setSelectedItems(items);
        setShowMoreModal(true);
    };
    const handleCloseMoreModal = () => setShowMoreModal(false);

    const renderLozenges = (items, type) => (
        <>
            {items.slice(0, 2).map((item, index) => (
                <div
                    onClick={() => handleCardClick(item)}
                    className="ms-2 mt-2 cursor-pointer"
                    key={`${type}-${index}`}
                >
                    <PlayerTeamLozenge data={item} player={true}/>
                </div>
            ))}
            {items.length > 2 && (
                <div className="ms-2 mt-2 text-white small align-self-center" onClick={() => handleOpenMoreModal(items)}>
                    +{items.length - 2} more
                </div>
            )}
        </>
    );

    return (
        <div>
            <div>
                <div className='d-flex justify-content-between align-items-center mb-3'>
                    <div className="d-flex align-items-center justify-content-start">
                        <img className="object-fit-contain disc-3 disc-md-45 rounded-half flex-none bg-white object-fit-cover" src={'https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=' + source_url + '&size=64'} alt="icon" />
                        <p className="mb-0 ms-2 small">{source_name && <span>{source_name}&nbsp;&middot;&nbsp;</span>}{news_date && <span className='text-neutral-gray-700'>{durationFormatter(new Date(news_date).valueOf())}</span>}</p>
                    </div>
                    <p className='text-crayola-red-500 mb-0 text-end'> Saved for later <i
                        title="Remove Bookmark"
                        className="fa-solid fa-bookmark cursor-pointer ps-2"
                        onClick={handleBookmarkClick}
                    ></i></p>
                </div>
                <a href={source_url} target='_blank' rel='noreferrer' className={'text-neutral-gray-500 mb-2' + (recognised_players || recognised_team) ? 'mb-1 text-decoration-none' : 'mb-3 text-decoration-none'} >{stringTruncate(text, 100)}</a>
            </div>
            <div className="d-flex justify-content-end align-items-start flex-wrap flex-1">
                {recognised_players.length > 0 && renderLozenges(recognised_players, 'player')}
                {recognised_team.length > 0 && renderLozenges(recognised_team, 'team')}
            </div>
            <Modal size="lg" centered show={showMoreModal} onHide={handleCloseMoreModal} >
                <Modal.Body className='bg-dark-mode-200 rounded-2 position-relative'>
                    <button type="button" className="position-absolute z-index-1 end-0 top-0 disc-4 bg-neutral-gray-300 text-black disc-md-45 btn-close-dark mt-n3 me-md-n35 me-n3"
                        onClick={handleCloseMoreModal} >
                    </button>
                    <div className="d-flex flex-wrap">
                        {selectedItems.map((item, index) => (
                            <div
                                key={index}
                                className="ms-2 mt-2 cursor-pointer"
                                onClick={() => handleCardClick(item)}
                            >
                                <PlayerTeamLozenge data={item}  player={true}/>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default SavedNewsCard
import { authHeader } from "../helpers/authHeader";

export const apiService = {
  apiPostForm,
  apiDelete,
  apiGet,
  apiPostJson,
};

function apiPostJson(url, formData, contentType = 'application/json') {
  const headers = {
      ...authHeader(),
      'Content-Type': contentType,
  };

  const body = contentType === 'application/x-www-form-urlencoded'
      ? formData
      : JSON.stringify(formData);

  const requestOptions = {
      method: 'POST',
      headers,
      body,
  };

  return fetch(url, requestOptions)
    .then(res =>
      {
        if(res.status!==200 && res.status!==201)
         {
            throw new Error(res.status)
         } else {
           return res.json()
         }
      }
    )
    .then(result => {
        return result;
    });
}




function apiPostForm(url, formData) {

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: formData
  };

  return fetch(url, requestOptions)
    .then(res => {
      if (res.status !== 200 && res.status !== 201) {
        throw new Error(res.status)
      } else {
        return res.json()
      }
    }
    )
    .then(result => {
      return result;
    });
}

function apiDelete(url) {

  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(url, requestOptions)
    .then(res => {
      if (res.status !== 200 && res.status !== 201) {
        throw new Error(res.status)
      } else {
        return res.json()
      }
    }
    )
    .then(result => {
      return result;
    });
}

function apiGet(url) {

  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(url, requestOptions)
    .then(res => {
      if (res.status !== 200 && res.status !== 201) {
        throw new Error(res.status)
      } else {
        return res.json()
      }
    }
    )
    .then(result => {
      return result;
    });
}

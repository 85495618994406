import React from 'react';
import CEO from '../../images/77a913140bf65abf45d5bac6f1ba8d94.png';
import Lead from '../../images/f89f1b4fa97bcc62fc5b685c833d8ec4.jfif';
import David_Davis from '../../images/f61c63bd-5317-40c5-a13e-71a8d8adb212.png';

const OurTeam = () => {
    return (
        <div className="container position-relative w-100 center-content pb-md-145 pb-65">
            <div className='max-w-f-1400 w-100'>
                <h2 className="fw-extra-bold mb-md-5 mb-3">Meet our team</h2>
                <div className='row row-cols-md-3  gx-md-4 gx-2 gy-4'>
                    <div>
                        <img src={CEO} alt='Sarah Bardsley' className='w-100 rounded-2' />
                        <p className='mb-0 fw-normal mt-3'>Sarah Bardsley</p>
                        <p className='text-neutral-gray-700 mb-2'>CEO</p>
                        <p className='text-neutral-gray-700 small'>Sarah combines creative expertise with a passion for analytics and fast results. She has successfully scaled ventures through data-driven marketing strategies. With a creative portfolio that includes F1 clients, YouTube and Google, Sarah is passionate in delivering exceptional client service, efficiency and tailored, results-focused solutions that meet the fast-paced demands of the sports world.</p>
                        
                    </div>
                   
                    <div>
                        <img src={Lead} alt='Andrew Azariah' className='w-100 rounded-2' />
                        <p className='mb-0 fw-normal mt-3'>Andrew Azariah</p>
                        <p className='text-neutral-gray-700 mb-2'>CTO</p>
                        <p className='text-neutral-gray-700 small'>Andrew comes from a background of national security intelligence, specialising in humanitarian applications of AI. He was previously responsible for the Fenix Insight open-source intelligence system, a global monitoring and alert system, which boasts clients including the United Nations, NATO, Amnesty International and the Ministry of Defence.</p>
                 
                    </div>
                    
                    <div>
                        <img src={David_Davis} alt='David Davis' className='w-100 rounded-2' />
                        <p className='mb-0 fw-normal mt-3'>David Davis OBE</p>
                        <p className='text-neutral-gray-700 mb-2'>Board Advisor</p>
                        <p className='text-neutral-gray-700 small'>Former head of the FA (British Football Association) and BBC journalist, David Davis is known for his charity work and founded the Kick It Out anti-racism campaign. He was awarded his OBE in 2007 for services to sport.</p>
                 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurTeam
import React, { useState } from 'react'
import FilterResults from '../components/FilterResults'
import Modal from '../templates/Modal';
import Breadcrumb from '../templates/Breadcrumb';
const PlayerListing = () => {
    const [modalMessage, setModalMessage] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const breadcrumbItems = [
        { label: 'Home', to: '/' },
        { label: 'Player listing' }
    ];
    return (
        <div>
            <Breadcrumb items={breadcrumbItems} />
            {showModal &&
                <Modal message={modalMessage} onClose={() => setShowModal(false)} />
            }
            <FilterResults onError={(message) => { setShowModal(true); setModalMessage(message) }} />
        </div>
    )
}

export default PlayerListing
